import React from 'react';
import ReactDOM from 'react-dom';
import './styles/index.css';
import Cyclops from './Cyclops';
import { MuiThemeProvider } from '@material-ui/core/styles';
import theme from './configure-theme';
import TagManager from 'react-gtm-module';

if(process.env.REACT_APP_BUILD === 'production') {
    const tagManagerArgs = {
        gtmId: 'GTM-5WJVTS6'
    }

    TagManager.initialize(tagManagerArgs);
}
// https://httpbin.org/status/500
// https://httpbin.org/json
// <Cyclops apiUrl='https://us-central1-degrees-46.cloudfunctions.net/catalogcyclops-sandbox'/>
const currency: string = window && window.location && window.location.hash && window.location.hash.replace('#currency=', '')
// || sessionStorage.getItem('currencyStorage');
const apiUrl: string = `${process.env.REACT_APP_BUILD === 'production' ? `https://us-central1-degrees-46.cloudfunctions.net/catalogcyclops-production/?currency=${currency}` : `https://us-central1-degrees-46.cloudfunctions.net/catalogcyclops-sandbox/?currency=${currency}`}`;
//sessionStorage.setItem('currencyStorage', currency)

ReactDOM.render(
    <React.StrictMode>
        <MuiThemeProvider theme={theme}>
            <Cyclops apiUrl={currency && apiUrl}/>
        </MuiThemeProvider>
    </React.StrictMode>,
    document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
