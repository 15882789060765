import {
    makeStyles,
    createStyles, Theme,
} from '@material-ui/core';
import {
    useHistory,
}                       from 'react-router-dom';
import React, {useEffect, useState} from 'react';
import AppBar           from '@material-ui/core/AppBar';
import Toolbar          from '@material-ui/core/Toolbar';
import Typography       from '@material-ui/core/Typography';
import IconButton       from '@material-ui/core/IconButton';
import ArrowBackIcon    from '@material-ui/icons/ArrowBack';
import {createMuiTheme, MuiThemeProvider} from "@material-ui/core/styles";
import {Product as ProductTypes} from "../../CyclopsTypes";


const useStyles = makeStyles((theme) => createStyles({
    toolbar: {
        padding: 0,
        minWidth: 320,
        width: '100%',
        [theme.breakpoints.down(611)]: {
            width: '-webkit-fill-available',
        },
        [theme.breakpoints.up(612)]: {
            paddingLeft: '25vw',
            paddingRight: '27vw',
            width: '48vw',
        },
        [theme.breakpoints.up(1000)]: {
            paddingLeft: '35vw',
            paddingRight: '35vw',
            width: '400px',
        },
    },
    title: {
        flexGrow: 1,
        fontSize: 14
    },

}));

type HideSearch = {
    hideSearch: boolean;
}

const NavigationHeader: React.FC<HideSearch> = function CatalogAppBar(hideSearch) {
    const {
        toolbar,
        title,
    } = useStyles();

    const history = useHistory();

    type ExtendCatalogTypes = {
        categoryTitle?: string;
        categoryCover?: string;
        products?: ProductTypes[];
    }

    const [initialData, setInitialData] = useState<ExtendCatalogTypes | null>(null)

    useEffect(()=>{
        if(history && history.location && history.location.state) {
            const state: ExtendCatalogTypes = history.location.state
            setInitialData(state)
        }
        //eslint-disable-next-line
    }, [history.location.state])

    const themeOverrides: Theme  =   createMuiTheme({
        palette: {
            primary: {
                main: `${(initialData && initialData.products.length < 50 && initialData.products[0].design.background_color) || '#28c339'}`,
            }
        }
    });

    return(
        <MuiThemeProvider theme={themeOverrides}>
            <AppBar
                style={{
                    flexGrow: 1,
                    position: 'fixed',
                    top: !hideSearch.hideSearch ? 87 : 0
                }}
                position='sticky'>
                <Toolbar
                    className={toolbar}>
                    <IconButton
                        aria-label='volver'
                        onClick={event => {
                            event.preventDefault();
                            history.go(-1);
                        }}>
                        <ArrowBackIcon
                            style={{
                                color: initialData && initialData.products && initialData.products.length !== 0 && (initialData.products[0].design.background_color === '#F0FFFF' || initialData.products[0].design.background_color === '#FFFFFF') ? '#000000' : '#FFFFFF'
                            }}/>
                    </IconButton>
                    <Typography
                        variant='h6'
                        className={title}
                        style={{
                            color: initialData && initialData.products && initialData.products.length !== 0 && (initialData.products[0].design.background_color === '#F0FFFF' || initialData.products[0].design.background_color === '#FFFFFF') ? '#000000' : '#FFFFFF'
                        }}>
                        { initialData && initialData.categoryTitle }
                    </Typography>
                </Toolbar>
            </AppBar>
        </MuiThemeProvider>
    );
};

export default NavigationHeader;
