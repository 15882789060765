import React                        from 'react'
import EcuadorErrorIcon             from '../../assets/ecuador-error-icon.png'
import EcuadorErrorBackgroundFooter from '../../assets/ecuador-error-background-footer.png'
import EcuadorErrorBackgroundHeader from '../../assets/ecuador-error-background-header.png'
import Paper                        from "@material-ui/core/Paper";
import makeStyles                   from "@material-ui/core/styles/makeStyles";
import {createStyles}               from "@material-ui/core";

const useStyles = makeStyles(theme => createStyles({
    responseContainer: {
        backgroundColor: 'transparent',
        padding: 32,
        display: 'flex',
        marginLeft: 'auto',
        marginRight: 'auto',
        flexFlow: 'column nowrap',
        alignItems: 'center',
        zIndex: 9,
        [theme.breakpoints.down(600)]: {
            width: '100vw',
            maxWidth: '100vw',
        },
        [theme.breakpoints.up(601)]: {
            width: 300,
            maxWidth: 300,
        },
    },
    text: {
        color: 'dimgrey',
        fontSize: 14,
        textAlign: 'center',
        width: '70%',
        maxWidth: 200,
        marginTop: 14,
        zIndex: 9,
    },
    iconFailure: {
        position: 'absolute',
        zIndex: 9,
        height: 'auto',
        [theme.breakpoints.down(330)]: {
            width: 250,
        },
        [theme.breakpoints.up(331)]: {
            width: 300,
        },
        '@media (min-height: 661px)': {
            bottom: 130,
        },
        '@media (max-height: 660px)': {
            bottom: 0,
        }
    },
    backgroundFailureFooter: {
        position: 'absolute',
        [theme.breakpoints.down(450)]: {
            right: 0,
            width: '100vw'
        },
        bottom: 0,
        zIndex: 1,
        height: 'auto',
        maxWidth: 450
    },
    backgroundFailureHeader: {
        position: 'absolute',
        [theme.breakpoints.down(450)]: {
            left: 0,
            width: '100vw',
            maxWidth: '100vw',
        },
        [theme.breakpoints.up(451)]: {
            width: 300,
            maxWidth: 300,
        },
        top: 0,
        zIndex: 4,
        height: 'auto',
        width: 'fit-content',
    },
    button: {
        color: "white",
        backgroundColor: "#28C339",
        borderColor: "#28C339",
        marginTop: 10,
        zIndex: 9,
        width: '100%',
        "&:hover":{
            backgroundColor: "white",
            color: '#28C339',
            borderColor: "#28C339",
        }
    },
}));

const EcuadorMessage: React.FC = function EcuadorMessage() {
    const {
        responseContainer,
        iconFailure,
        backgroundFailureHeader,
        backgroundFailureFooter,
    } = useStyles();

    return(
        <div data-testid='no-country-available'>
            <Paper data-testid='error-screen' elevation={0} className={responseContainer} style={{ width: '80%' }}>
                <img alt="" src={EcuadorErrorBackgroundHeader} className={backgroundFailureHeader}/>
                <img alt="" src={EcuadorErrorIcon} className={iconFailure}/>
                <img alt="" src={EcuadorErrorBackgroundFooter} className={backgroundFailureFooter}/>
                {
                    /*
                    <div>
                    <a href={urlHome}>
                        <Button
                            color="primary"
                            className={button}
                            variant="outlined"
                        > Visita nuestro sitio web
                        </Button>
                    </a>
                    <Typography variant={'body2'} className={text}>Para enterarte de todas las novedades</Typography>
                </div>
                     */
                }
            </Paper>
        </div>
    )
}

export default EcuadorMessage