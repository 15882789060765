import React, { useEffect, Fragment } from 'react';
import { withRouter } from 'react-router-dom';

// @ts-ignore
function ScrollOnTopHook({ history, children }): any {
    useEffect(() => {
        const unlisten = history.listen(() => {
            window.scrollTo(0, 0);
        });
        return () => {
            unlisten();
        }

        //eslint-disable-next-line
    }, []);

    return <Fragment>{children}</Fragment>;
}

// @ts-ignore
export default withRouter(ScrollOnTopHook);