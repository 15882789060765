import React, {
    useState,
    useEffect }                     from 'react';
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Redirect
} from 'react-router-dom';

import {
    CyclopsProps,
    CyclopsData
}                                   from './CyclopsTypes';
import Catalog                      from './components/Catalog/Catalog';
import ExtendCatalog                from './components/Catalog/ExtendCatalog';
import Product                      from './components/Product/Product';
import ScrollOnTopHook              from './components/CustomHooks/ScrollOnTopHook';
import Login                        from "./components/Login/Login";
import EcuadorMessage               from "./components/NoCountryAvailable/EcuadorMessage";
import FullProductList              from "./components/Catalog/FullProductList";
import NoCountryAvailable           from "./components/NoCountryAvailable/NoCountryAvailable";
import ContainerHeader              from "./components/Search/ContainerHeader";
import mockDataMexico               from './mocks/ta3_mock_sandbox.json'
//import mockDataChile              from './mocks/cyclops_categories.json'
//import mockDataMexico             from './mocks/cyclops_categories_mxn.json'
//import mockDataArgentina          from './mocks/cyclops_categories_ars.json'

import {
    makeStyles,
    createStyles
}                           from '@material-ui/core';
import Paper                from '@material-ui/core/Paper';
import CircularProgress     from '@material-ui/core/CircularProgress';
import Typography           from '@material-ui/core/Typography';
import ErrorIcon            from '@material-ui/icons/Error';
import green                from '@material-ui/core/colors/green';



const useStyles = makeStyles(theme => createStyles({
    responseContainer: {
        padding: 32,
        display: 'flex',
        flexFlow: 'column nowrap',
        alignItems: 'center',
        backgroundColor: 'floralwhite',
        margin: '16px 0',
        width: 320
    },
    iconFailure: {
        color: 'red',
        fontSize: 120,
        marginTop: -10
    },
    title: {
        color: 'black',
        fontWeight: 'normal',
        margin: '10px auto'
    },
    text: {
        color: 'dimgrey',
        fontSize: 14,
        textAlign: 'center',
        marginBottom: 24
    },
    loadingContainer: {
        display: 'flex',
        flexFlow: 'column nowrap',
        alignItems: 'center',
        background: 'white',
        marginLeft: 'auto',
        marginRight: 'auto',
        padding: 16,
        [theme.breakpoints.up(480)]: {
            width: 460
        },
        [theme.breakpoints.up(1024)]: {
            marginTop: 64
        },
    }
}));

const Cyclops: React.FC<CyclopsProps> = function Cyclops({ apiUrl }) {
    const {
        loadingContainer,
        responseContainer,
        iconFailure,
        title,
        text
    } = useStyles();

    const [ loading, setLoading ]                                       = useState<boolean>(true);
    const [ error, setError ]                                           = useState<boolean>(false);
    const [ errorCode, setErrorCode ]                                   = useState<number>(0);
    const [ errorMessage, setErrorMessage ]                             = useState<string>('');
    const [ data, setData ]                                             = useState<CyclopsData | null>(null);
    const [ isNotCategoriesFilter, setIsNotCategoriesFilter]            = useState<boolean>(false);
    const [ isNotProductsFilter, setIsNotProductsFilter]                = useState<boolean>(false);
    const [ showCountryWindowProblem, setShowCountryWindowProblem ]     = useState<boolean>(false);
    const [ flagFetchingDataIsEmpty, setFlagFetchingDataIsEmpty ]       = useState<boolean>(false);
    const [ messageEcuador, setMessageEcuador ]                         = useState<boolean>(false);
    const [ isPageReloaded, setIsPageReloaded ]                         = useState<boolean>(false);

    const callCFCountry = () => {
        type CountryCF = {
            countryName: string;
        };
        //setCountry('Ecuador')
        const url = 'https://us-central1-degrees-46.cloudfunctions.net/country-detector-sandbox'
        fetch(url)
            .then(async response => {
                const dataRes: CountryCF = await response.json();
                if(dataRes && dataRes.countryName === 'Ecuador'){
                    setMessageEcuador(true)
                }
            })
            .catch(error => {
                console.log(error)
            });
    }

    useEffect( () => {
        if(apiUrl) {
            callCFCountry()
            setLoading(true);
            //@ts-ignore
            //setData(mockDataArgentina)
            fetch(apiUrl)
                .then(async response => {
                    if(response.status === 200) {
                        const dataRes: CyclopsData = await response.json();
                        setLoading(false);
                        Object.keys(dataRes).length === 0 && setFlagFetchingDataIsEmpty(true)
                        dataRes.categories && dataRes.categories.length < 3 && setIsNotCategoriesFilter(true)
                        dataRes.products && dataRes.products.length < 3 && setIsNotProductsFilter(true)
                        setData(dataRes);
                    } else {
                        setLoading(false);
                        setError(true);
                        setErrorCode(response.status);
                        setErrorMessage('Error retrieving data from sources ');
                    }
                })
                .catch(error => {
                    setLoading(false);
                    setError(true);
                    setErrorCode(1);
                    setErrorMessage(`${error} `);
                });
        } else {
            setIsPageReloaded(true)
        }
    }, [ apiUrl ]);

    //if there is no data, show window country problem
    useEffect(() => {
        const localData : CyclopsData = data
        if((!localData || (localData && Object.keys(localData).length === 0)) && !loading && flagFetchingDataIsEmpty && !messageEcuador ) {
            setShowCountryWindowProblem(true)
            setLoading(false);
        } else {
            setShowCountryWindowProblem(false);
            (data || isPageReloaded) && setLoading(false);
        }
    }, [loading, data, flagFetchingDataIsEmpty, messageEcuador, isPageReloaded])

    //only works on sandbox enviornment, not in localhost. For that, use the commented section
    useEffect(() => {
        const currency: string = window && window.location && window.location.hash && window.location.hash.replace('#currency=', '')
        //@ts-ignore
        isNotProductsFilter && process.env.REACT_APP_BUILD === 'sandbox' && currency === 'MXN' && setData(mockDataMexico)

        //force to use the mocked data from Mexico, sandbox TA3 is usually failing
        /*//@ts-ignore
        setData(mockDataMexico)
        setIsNotCategoriesFilter(true)
        setError(false);*/
    }, [isNotProductsFilter, error])

    return(
        <Router>
            {
                ((data && data.products) || isPageReloaded) && !loading && !showCountryWindowProblem && !messageEcuador && (
                    <ContainerHeader data={data}/>
                )
            }
            <ScrollOnTopHook>
                <Switch>
                    <Route exact path='/login'>
                        <Login/>
                    </Route>
                    <Route exact path='/'>
                        {
                            !loading && showCountryWindowProblem && (
                                <NoCountryAvailable/>
                            )
                        }
                        <section style={{ width: '100%', maxWidth: 920 }}>
                            {
                                loading && (
                                    <Paper data-testid='loading-screen' elevation={0} className={loadingContainer} style={{ marginTop: '40vh', marginLeft: 'auto', marginRight: 'auto' }}>
                                        <CircularProgress
                                            style={{ color: green[500], marginTop: 16 }}
                                            thickness={4}
                                            size={60}/>
                                    </Paper>
                                )
                            }
                            {
                                error && !loading && (
                                    <Paper data-testid='error-screen' elevation={0} className={responseContainer} style={{ width: '80%' }}>
                                        <ErrorIcon className={iconFailure}/>
                                        <Typography variant={'h6'} className={title}>{`ERROR: ${errorCode}`}</Typography>
                                        <Typography variant={'body2'} className={text}>Algo salio mal, por favor vuelve a intentarlo mas tarde.</Typography>
                                        <Typography variant={'body2'} className={text}>`${errorMessage}`</Typography>
                                    </Paper>
                                )
                            }
                            {
                                messageEcuador && !loading && (
                                    <EcuadorMessage/>
                                )
                            }
                            {
                                data && !loading && !messageEcuador && (
                                    <Catalog data={data}/>
                                )
                            }
                        </section>
                    </Route>
                    <Route exact path='/product/:id'>
                        <Product/>
                    </Route>
                    {
                        isNotCategoriesFilter ?
                            <Route exact path='/extend-catalog/:id'>
                                <FullProductList data={data}/>
                            </Route>
                            :
                            <Route exact path='/extend-catalog/:id'>
                                <ExtendCatalog/>
                            </Route>
                    }

                    <Route path='*'>
                        <Redirect to={`/#currency=${sessionStorage.getItem('currencyStorage')}`} />
                    </Route>
                </Switch>
            </ScrollOnTopHook>
        </Router>
    );

}

export default Cyclops;