import {
    Product as ProductTypes,
    GetDebtInfoUserData,
    CreateOrderInfoUserData,
    GetDebtInfoUserDataValue
} from '../../CyclopsTypes';
import ProductAppBar from './ProductAppBar'
import InfoModal from './InfoModal'
import {
    makeStyles,
    createStyles,
    useTheme, Theme
} from '@material-ui/core';
import { createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles';
import {
    useHistory,
} from 'react-router-dom';
import React, { useState, useEffect } from 'react';
import Typography from '@material-ui/core/Typography';
import Button from "@material-ui/core/Button";
import Paper from "@material-ui/core/Paper";
import TextField from '@material-ui/core/TextField';
import FormControl from "@material-ui/core/FormControl";
import Select from '@material-ui/core/Select';
import CircularProgress from "@material-ui/core/CircularProgress";
import green from "@material-ui/core/colors/green";
import ErrorIcon from '@material-ui/icons/Error';
import DoneAllIcon from '@material-ui/icons/DoneAll';
import SwipeableViews from 'react-swipeable-views';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Switch from '@material-ui/core/Switch';
import FormHelperText from '@material-ui/core/FormHelperText';
import GridList from "@material-ui/core/GridList";
import GridListTile from "@material-ui/core/GridListTile";


const useStyles = makeStyles(() => createStyles({
    menuButton: {
        marginLeft: -12,
        marginRight: 20,
    },
    loadingContainer: {
        display: 'flex',
        flexFlow: 'column nowrap',
        alignItems: 'center',
        background: 'white',
        paddingTop: '25%',
        paddingBottom: '25%'
    },
}));

type TabPanelProps = {
    children?: React.ReactNode;
    dir?: string;
    index: any;
    value: any;
}

function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;
    return (
        <div
            style={{
                marginTop: 16,
            }}
            role='tabpanel'
            hidden={value !== index}
            id={`full-width-tabpanel-${index}`}
            aria-labelledby={`full-width-tab-${index}`}
            {...other}>
            {value === index && (
                <div>{ children }</div>
            )}
        </div>
    );
}

const Product: React.FC = function Product() {
    const theme = useTheme();
    const {
        loadingContainer
    } = useStyles();

    const history = useHistory();
    type ProductsState = {
        categoryTitle?: string;
        categoryCover?: string;
        products?: ProductTypes[];
    };
    const [ showLoadingScreen, setShowLoadingScreen ] = useState<boolean>(false);
    const [ showErrorScreen, setShowErrorScreen ] = useState<boolean>(false);
    const [ errorStatusCode, setErrorStatusCode ] = useState<string>('');
    const [ errorStatusMessage, setErrorStatusMessage ] = useState<string>('');
    const [ showTopupScreen, setShowTopupScreen ] = useState<boolean>(true);
    const [ userSelectedProduct, setUserSelectedProduct ] = useState<ProductTypes | null >(null);
    const [ autoSelectedProduct, setAutoSelectedProduct ] = useState<ProductTypes | null>(null);
    const [ debtNumber, setDebtNumber ] = useState<string | null>(null)
    type Params = {
        name: string;
        value: string
    };
    const [ params, setParams ] = useState<Params[]>([]);
    const [ inputConfirmations, setInputConfirmations ] = useState<Params[]>([]);
    const [ initialData, setInitialData ] = useState<ProductsState>({
        categoryTitle: 'Category Title',
        categoryCover: 'https://via.placeholder.com/1920x1080?text=CoverImage',
        products: []
    });
    const [ isAutoSelectedProduct, setIsAutoSelectedProduct ] = useState<boolean>(false);
    const [ userSelectedProductNeedDebtQuery, setUserSelectedProductNeedDebtQuery ] = useState<boolean>(false);
    const [ autoSelectedProductNeedDebtQuery, setAutoSelectedProductNeedDebtQuery ] = useState<boolean>(false);
    const [ showTabsForDebtQuery, setShowTabsForDebtQuery ] = useState<boolean>(false);
    const [ autoSelectedProductIDTabs, setAutoSelectedProductIDTabs ] = useState<GetDebtInfoUserData[] | null>(null);
    const [ autoSelectedProductIDTabsInput, setAutoSelectedProductIDTabsInput ] = useState<GetDebtInfoUserData[] | null>(null);
    const [ autoSelectedProductIDAlternativeTabsInput, setAutoSelectedProductIDAlternativeTabsInput ] = useState<GetDebtInfoUserData[] | null>(null);

    const [ userSelectedProductIDTabs, setUserAutoSelectedProductIDTabs ] = useState<GetDebtInfoUserData[] | null>(null);
    const [ userSelectedProductIDTabsInput, setUserAutoSelectedProductIDTabsInput ] = useState<GetDebtInfoUserData[] | null>(null);
    const [ userSelectedProductIDAlternativeTabsInput, setUserAutoSelectedProductIDAlternativeTabsInput ] = useState<GetDebtInfoUserData[] | null>(null);

    const [ productImage, setProductImage ] = useState<string>(initialData.categoryCover);
    const [ dataName, setDataName ] = useState<string>('');
    const [ dataUid, setDataUid ] = useState<string>('');
    const [ dataEmail, setDataEmail ] = useState<string>('');
    const [ showSuccessScreen, setShowSuccessScreen ] = useState<boolean>(false);
    const [ tabsValue, setTabsValue ] = useState<number>(0);
    const [ allDataProduct, setAllDataProduct ] = useState<GenericObject | null>(null)

    const [ showDebtQuerySuccessResponseScreen, setShowDebtQuerySuccessResponseScreen ] = useState<boolean>(false);

    type DebtResult = {
        debt_result: {
            amount: number;
            dueAmount: number;
            id: string;
            id_transaction: string;
            printLine: string;
            debt_number?: string;
        }[]
    };

    const [ debtQuerySuccessResponse, setDebtQuerySuccessResponse ] = useState<DebtResult | null>(null);
    const [ debtQueryAmount, setDebtQueryAmount ] = useState<number | null>(null);
    const [ queryDebtIdChecked, setQueryDebtIdChecked ] = useState<string>('');
    const [ queryDebtIdTx, setQueryDebtIdTx ] = useState<string>('');
    const [ userEmail, setUserEmail ] = useState<string | null>(null);
    type TopupTextFieldErrorData = {
        fieldName: string;
        error: boolean;
        errorMessage: string;
    }
    const [ topupTextFieldErrorData, setTopupTextFieldErrorData ] = useState<TopupTextFieldErrorData[]>([]);

    type GenericObject = {
        [key: string]: string | number
    };
    type IOSData = {
        name: string;
        uid: string;
        email: string;
    };

    const handleTopupTextField: (userData: GetDebtInfoUserData | CreateOrderInfoUserData) => (event: React.ChangeEvent<HTMLInputElement>) => void = function handleTopupTextField(userData) {
        return function handleOnChange(event: React.ChangeEvent<HTMLInputElement>) {
            event.preventDefault();

            const value = (event.target as HTMLInputElement).value;
            const isRUTValue: boolean = userData.values.some(data => (data.name === 'is_rut' && data.value === true));
            const paramName: string = userData && userData.values && userData.values.length > 1 ? userData.values.filter(data => data.value === 'self')[0].name : userData.field_name;
            const extraParams: GetDebtInfoUserDataValue[] = userData && userData.values && userData.values.length > 1 ? userData.values.filter(data => data.value !== 'self') : [];
            //const fieldName = userData.field_name;

            type CurrentValues = {
                name: string,
                value: string
            }[];

            const currentValues: CurrentValues = [
                ...extraParams,
                {
                    name: paramName,
                    value
                }
            ];
            const allowedValueRange = (Number(value) >= userData.min_amount && Number(value) <= userData.max_amount);

            // check with regex provided
            if(userData && userData.regex && userData.regex.length > 2 && !isRUTValue && !userData.compare_to_field_name) {
                const regexpValidation: RegExp = new RegExp(userData.regex);
                const validation: boolean = regexpValidation.test(String(value).toLocaleLowerCase());
                if((validation && value.length > 1)) {
                    setParams(prevParams => {
                        const otherParams = prevParams.filter(param => param.name !== paramName).filter(param => !extraParams.includes(param));
                        return [
                            ...otherParams,
                            ...currentValues
                        ];
                    });
                    setTopupTextFieldErrorData(prevState => [
                        ...prevState.filter(errorData => errorData.fieldName !== userData.field_name),
                        {
                            fieldName: userData.field_name,
                            error: false,
                            errorMessage: ''
                        }
                    ]);
                } else {
                    setTopupTextFieldErrorData(prevState => [
                        ...prevState.filter(errorData => errorData.fieldName !== userData.field_name),
                        {
                            fieldName: userData.field_name,
                            error: true,
                            errorMessage: userData.error_message
                        }
                    ]);
                }
            }

            // check chilean rut id
            if(userData && isRUTValue && !userData.compare_to_field_name) {
                if(validateRut(value)) {
                    const currentValues: CurrentValues = [
                        ...extraParams,
                        {
                            name: paramName,
                            value: parseRutNumber(value)
                        }
                    ];
                    setParams(prevParams => {
                        const otherParams: Params[] = prevParams.filter(param => param.name !== paramName).filter(param => !extraParams.includes(param));
                        return [
                            ...otherParams,
                            ...currentValues
                        ];
                    });
                    setTopupTextFieldErrorData(prevState => [
                        ...prevState.filter(errorData => errorData.fieldName !== userData.field_name),
                        {
                            fieldName: userData.field_name,
                            error: false,
                            errorMessage: ''
                        }
                    ]);
                } else {
                    setTopupTextFieldErrorData(prevState => [
                        ...prevState.filter(errorData => errorData.fieldName !== userData.field_name),
                        {
                            fieldName: userData.field_name,
                            error: true,
                            errorMessage: userData.error_message
                        }
                    ]);
                }
            }

            // pass through empty regex
            if(userData && !userData.regex  && !isRUTValue && !userData.compare_to_field_name) {
                const currentValues: CurrentValues = [
                    ...extraParams,
                    {
                        name: paramName,
                        value
                    }
                ];
                setParams(prevParams => {
                    const otherParams: Params[] = prevParams.filter(param => param.name !== paramName).filter(param => !extraParams.includes(param));
                    return [
                        ...otherParams,
                        ...currentValues
                    ];
                });
            }

            // check for confirmation valueinputConfirmations
            if(userData && userData.compare_to_field_name) {
                const expectedValue: Params[] = params.length > 0 && params.filter(param => param.name === userData.compare_to_field_name);
                if(expectedValue && expectedValue[0].value === value) {
                    setInputConfirmations(prevParams => {
                        const otherParams: Params[] = prevParams.filter(param => param.name !== paramName).filter(param => !extraParams.includes(param));
                        return [
                            ...otherParams,
                            ...currentValues
                        ];
                    });
                    setTopupTextFieldErrorData(prevState => [
                        ...prevState.filter(errorData => errorData.fieldName !== userData.field_name),
                        {
                            fieldName: userData.field_name,
                            error: false,
                            errorMessage: ''
                        }
                    ]);
                } else {
                    setTopupTextFieldErrorData(prevState => [
                        ...prevState.filter(errorData => errorData.fieldName !== userData.field_name),
                        {
                            fieldName: userData.field_name,
                            error: true,
                            errorMessage: userData.error_message
                        }
                    ]);
                }
            }

            if(userData && userData.field_type === 'amount' && userData.min_amount && userData.max_amount) {
                if(allowedValueRange) {
                    setParams(prevParams => {
                        const otherParams = prevParams.filter(param => param.name !== paramName).filter(param => !extraParams.includes(param));
                        return [
                            ...otherParams,
                            ...currentValues
                        ];
                    });
                    setTopupTextFieldErrorData(prevState => [
                        ...prevState.filter(errorData => errorData.fieldName !== userData.field_name),
                        {
                            fieldName: userData.field_name,
                            error: false,
                            errorMessage: ''
                        }
                    ]);
                } else {
                    setTopupTextFieldErrorData(prevState => [
                        ...prevState.filter(errorData => errorData.fieldName !== userData.field_name),
                        {
                            fieldName: userData.field_name,
                            error: true,
                            errorMessage: userData.error_message
                        }
                    ]);
                }
            }
        }
    };

    const handleTopupButton: (event: React.MouseEvent<HTMLButtonElement>) => void = async function handleTopupButton(event) {
        event.preventDefault();
        try {
            //TODO: next message
            //try to delete setUserSelectedProduct and userSelectedProduct, because it's useless
            setUserSelectedProduct(null)

            setShowTopupScreen(false);
            setShowLoadingScreen(true);
            const selectedProductFixedData: GenericObject = userSelectedProduct && userSelectedProduct.create_order_info.fixed_data.reduce((o, key) => ({ ...o, [key.field_name]: key.field_value}), {});
            const autoSelectedProductFixedData: GenericObject = initialData && initialData.products.length === 1 && initialData.products[0].create_order_info.fixed_data.reduce((o, key) => ({ ...o, [key.field_name]: key.field_value}), {});
            const userData: GenericObject = params.reduce((o, key) => ({ ...o, [key.name]: key.value}), {})
            const fixedData: GenericObject = userSelectedProduct && selectedProductFixedData ? selectedProductFixedData : autoSelectedProductFixedData;
            const endpointUrl: string = (userSelectedProduct && userSelectedProduct.create_order_info.endpoint_url) || (initialData && initialData.products.length === 1 && initialData.products[0].create_order_info.endpoint_url);
            const method: string = (userSelectedProduct && userSelectedProduct.create_order_info.method) || (initialData && initialData.products.length === 1 && initialData.products[0].create_order_info.method);
            const headers: HeadersInit = (userSelectedProduct && userSelectedProduct.create_order_info.headers) || (initialData && initialData.products.length === 1 && initialData.products[0].create_order_info.headers);
            const serviceName: string = initialData && initialData.products && initialData.products[0].name && initialData.products[0].name

            setAllDataProduct(prevState => ({...prevState, ...userData, ...fixedData}))

            const result: Response = await fetch(endpointUrl, {
                method,
                headers,
                body: JSON.stringify({
                    ...fixedData,
                    ...userData,
                    service_name: serviceName
                })
            });
            const { status } = result;
            if(status === 201) {
                const response = await result.json();
                const name: string = (userSelectedProduct && userSelectedProduct.name) || (initialData && initialData.products.length === 1 && initialData.products[0].name);
                const uid: string = (response && response.id) || (response && response.uuid);
                const email: string = params && params.length > 0 && params.filter(param => param.name === 'email')[0].value;

                setDataName(name);
                setDataUid(uid);
                setDataEmail(email);

                const iosData: IOSData = {
                    name,
                    uid,
                    email
                };

                setShowLoadingScreen(false);
                setShowSuccessScreen(true);

                if((window as any).webkit) {
                    (window as any).webkit.messageHandlers['iosHandler'].postMessage(JSON.stringify(iosData))
                }

                if((window as any).Android) {
                    (window as any).Android.createImpulsiveOrder(name, uid, email);
                }

            } else {
                const response = await result.json();
                setShowLoadingScreen(false);
                setShowErrorScreen(true);
                setErrorStatusCode(`${status}`);
                setErrorStatusMessage((response && response.errors && response.errors[0].detail) || (response && response[0].detail));
            }

        } catch(error) {
            setShowLoadingScreen(false);
            setShowErrorScreen(true);
            setErrorStatusCode(`${0}`);
            setErrorStatusMessage(String(error));
        }
    };

    const handleDebtQueryButton: (event: React.MouseEvent<HTMLButtonElement>) => void = async function handleDebtQueryButton(event) {
        event.preventDefault();
        try{
            setShowTopupScreen(false);
            setShowLoadingScreen(true);
            const selectedProductFixedData: GenericObject = userSelectedProduct && userSelectedProduct.get_debt_info.fixed_data.reduce((o, key) => ({ ...o, [key.field_name]: key.field_value}), {});
            const autoSelectedProductFixedData: GenericObject = autoSelectedProduct && autoSelectedProduct.get_debt_info && autoSelectedProduct.get_debt_info.fixed_data && autoSelectedProduct.get_debt_info.fixed_data.reduce((o, key) => ({ ...o, [key.field_name]: key.field_value}), {});
            const userData: GenericObject = params.reduce((o, key) => ({ ...o, [key.name]: key.value}), {})
            const fixedData: GenericObject = userSelectedProduct && selectedProductFixedData ? selectedProductFixedData : autoSelectedProductFixedData;
            const endpointUrl: string = (userSelectedProduct && userSelectedProduct.get_debt_info.endpoint_url) || (autoSelectedProduct && autoSelectedProduct.get_debt_info.endpoint_url);
            const method: string = (userSelectedProduct && userSelectedProduct.get_debt_info.method) || (autoSelectedProduct && autoSelectedProduct.get_debt_info.method);
            const headers: HeadersInit = (userSelectedProduct && userSelectedProduct.get_debt_info.headers) || (autoSelectedProduct && autoSelectedProduct.get_debt_info.headers);
            const serviceName: string = initialData && initialData.products && initialData.products[0].name && initialData.products[0].name

            setAllDataProduct(prevState => ({...prevState, ...userData, ...fixedData}))

            const result: Response = await fetch(endpointUrl, {
                method,
                headers,
                body: JSON.stringify({
                    ...fixedData,
                    ...userData,
                    service_name: serviceName
                })
            });


            const { status } = result;
            if(status === 200) {
                const response = await result.json();
                setShowDebtQuerySuccessResponseScreen(true);
                // todo: set new debt response
                setDebtQuerySuccessResponse(response);
                /*                setDebtQuerySuccessResponse({
                                    debt_result: [
                                        {
                                            dueAmount: 0,
                                            amount: 0,
                                            id: '23r2rafad',
                                            printLine: 'product print line',
                                            id_transaction: '2gaadg3a3dfa'
                                        }
                                    ]
                                });*/

                /*                setDebtQuerySuccessResponse({
                                    debt_result: [
                                        {
                                            dueAmount: 0,
                                            amount: 12,
                                            id: '23r2rafad',
                                            printLine: 'product print line',
                                            id_transaction: '2gaadg3a3dfa'
                                        },
                                        {
                                            dueAmount: 0,
                                            amount: 123,
                                            id: '23r2rafasssssssd',
                                            printLine: 'product print line b',
                                            id_transaction: '2gaadg3a3nghghdfa'
                                        },
                                        {
                                            dueAmount: 0,
                                            amount: 1225,
                                            id: '23r2rgggggggfad',
                                            printLine: 'product print line c',
                                            id_transaction: '2gaadg3dffa3dfa'
                                        }
                                    ]
                                });*/
                setShowLoadingScreen(false);
                const currentUserEmailValue: Params[] = params.filter(param => {
                    const regexpValidation = new RegExp(/^(([^<>()\]\\.,;:\s@']+(\.[^<>()\]\\.,;:\s@']+)*)|('.+'))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);
                    return param && param.value && regexpValidation.test(String(param.value).toLocaleLowerCase()) && param;
                });
                const currentUserEmail: string = currentUserEmailValue && currentUserEmailValue.length > 0 && currentUserEmailValue[0].value;
                setUserEmail(currentUserEmail);
            } else {
                // todo: ask for error response
                const response = await result.json();
                setShowLoadingScreen(false);
                setShowErrorScreen(true);
                setErrorStatusCode(`${status}`);
                setErrorStatusMessage((response && response.errors && response.errors[0] && response.errors[0].detail) || (response && response[0] && response[0].detail));
            }

        } catch(error) {
            setShowLoadingScreen(false);
            setShowErrorScreen(true);
            setErrorStatusCode(`${0}`);
            setErrorStatusMessage(String(error));
        }
    }
    const handleTabsChange = (event: React.ChangeEvent<{}>, newValue: number) => {
        setTabsValue(newValue);
        setParams([]);
    };
    const handleTabsChangeIndex = (index: number) => setTabsValue(index);
    const handleDebtQuerySwitchOnChange: (amount: number, id: string, idTx: string, debt_number?: string) => (event: React.ChangeEvent<HTMLInputElement>) => void = function handleDebtQuerySwitchOnChange(amount, id, idTx, debt_number) {
        return function handleSwitchOnChange(event) {
            event.preventDefault();
            setQueryDebtIdChecked(id);
            setQueryDebtIdTx(idTx);
            setDebtQueryAmount(amount);
            setDebtNumber(debt_number)
        };
    };

    const handleDebtQueryTopupButton: (event: React.MouseEvent<HTMLButtonElement>) => void = async function handleDebtQueryTopupButton(event) {
        event.preventDefault();
        try{
            setShowDebtQuerySuccessResponseScreen(false);
            setShowLoadingScreen(true);
            const endpointUrl: string = (userSelectedProduct && userSelectedProduct.create_order_info.endpoint_url) || (autoSelectedProduct && autoSelectedProduct.create_order_info.endpoint_url);
            const selectedProductFixedData: GenericObject = userSelectedProduct && userSelectedProduct.create_order_info.fixed_data.reduce((o, key) => ({ ...o, [key.field_name]: key.field_value}), {});
            const autoSelectedProductFixedData: GenericObject = initialData && initialData.products.length === 1 && initialData.products[0].create_order_info.fixed_data.reduce((o, key) => ({ ...o, [key.field_name]: key.field_value}), {});
            const userData: GenericObject = params.reduce((o, key) => ({ ...o, [key.name]: key.value}), {})
            const fixedData: GenericObject = userSelectedProduct && selectedProductFixedData ? selectedProductFixedData : autoSelectedProductFixedData;
            const method: string = (userSelectedProduct && userSelectedProduct.create_order_info.method) || (autoSelectedProduct && autoSelectedProduct.create_order_info.method);
            const headers: HeadersInit = (userSelectedProduct && userSelectedProduct.create_order_info.headers) || (autoSelectedProduct && autoSelectedProduct.create_order_info.headers);
            const serviceName: string = initialData && initialData.products && initialData.products[0].name && initialData.products[0].name

            let result: Response

            // if its on Chile, it needs the extra key debt_number
            if(initialData.products[0].aliases.includes('Multicaja')){
                result = await fetch(endpointUrl, {
                    method,
                    headers,
                    body: JSON.stringify({
                        ...fixedData,
                        ...userData,
                        amount: debtQueryAmount,
                        id_transaction: queryDebtIdTx,
                        debt_number: debtNumber
                    })
                });
            }
            // if its on Mexico, it needs the extra key service_name
            else if (initialData.products[0].aliases.includes('pagaqui')){
                result = await fetch(endpointUrl, {
                    method,
                    headers,
                    body: JSON.stringify({
                        ...fixedData,
                        ...userData,
                        amount: debtQueryAmount,
                        id_transaction: queryDebtIdTx,
                        service_name: serviceName
                    })
                });
            }
            else {
                result = await fetch(endpointUrl, {
                    method,
                    headers,
                    body: JSON.stringify({
                        ...fixedData,
                        ...userData,
                        amount: debtQueryAmount,
                        id_transaction: queryDebtIdTx,
                    })
                });
            }

            const { status } = result;
            if(status === 201) {
                const response = await result.json();
                const name: string = (userSelectedProduct && userSelectedProduct.name) || (autoSelectedProduct && autoSelectedProduct.name);
                const uid: string = (response && response.id) || (response && response.uuid);

                setDataName(name);
                setDataUid(uid);
                setDataEmail(userEmail);

                const iosData: IOSData = {
                    name,
                    uid,
                    email: userEmail
                };

                setShowLoadingScreen(false);
                setShowSuccessScreen(true);

                if((window as any).webkit) {
                    (window as any).webkit.messageHandlers['iosHandler'].postMessage(JSON.stringify(iosData))
                }

                if((window as any).Android) {
                    (window as any).Android.createImpulsiveOrder(name, uid, userEmail);
                }

            } else {
                const response = await result.json();
                setShowLoadingScreen(false);
                setShowErrorScreen(true);
                setErrorStatusCode(`${status}`);
                setErrorStatusMessage((response && response.errors && response.errors[0].detail) || (response && response[0].detail));
            }

        } catch(error) {
            setShowLoadingScreen(false);
            setShowErrorScreen(true);
            setErrorStatusCode(`${0}`);
            setErrorStatusMessage(String(error));
        }
    };
    const handleTopupButtonDisabledState: () => boolean = function handleTopupButtonDisabledState() {
        // debt query topup button
        if((initialData && initialData.products.length === 1 && initialData.products[0].get_debt_info) ||
            (userSelectedProduct && userSelectedProduct.get_debt_info)) {
            const selectedProductUserData           : GetDebtInfoUserData[]     = userSelectedProduct && userSelectedProduct.get_debt_info.user_data;
            const autoSelectedProductUserData       : GetDebtInfoUserData[]     = initialData && initialData.products.length === 1 && initialData.products[0].get_debt_info.user_data;
            const productUserData                   : GetDebtInfoUserData[]     = userSelectedProduct && selectedProductUserData ? selectedProductUserData : autoSelectedProductUserData;
            const expectedAmountOfUserDataNeeded    : number                    = productUserData.filter(data => !data.compare_to_field_name).length;
            const confirmationInputsNeeded          : number                    = productUserData.filter(data => data.compare_to_field_name).length;
            const currentConfirmationsAmount        : number                    = inputConfirmations.length;
            const allParamsNeeded                   : boolean                   = expectedAmountOfUserDataNeeded === params.length;
            const allConfirmationsNeeded            : boolean                   = currentConfirmationsAmount === confirmationInputsNeeded;
            const errorsOnInputFields               : TopupTextFieldErrorData[] = topupTextFieldErrorData.filter(textField => textField.error);
            const hasErrors                         : boolean                   = errorsOnInputFields.length > 0;
            return !(allParamsNeeded && allConfirmationsNeeded && !hasErrors);

        }

        // normal topup button
        if((initialData && initialData.products.length === 1 && !initialData.products[0].get_debt_info) ||
            (userSelectedProduct && !userSelectedProduct.get_debt_info)) {
            const selectedProductUserData           : CreateOrderInfoUserData[] = userSelectedProduct && userSelectedProduct.create_order_info.user_data;
            const autoSelectedProductUserData       : CreateOrderInfoUserData[] = initialData && initialData.products.length === 1 && initialData.products[0].create_order_info.user_data;
            const productUserData                   : CreateOrderInfoUserData[] = userSelectedProduct && selectedProductUserData ? selectedProductUserData : autoSelectedProductUserData;
            const expectedAmountOfUserDataNeeded    : number                    = productUserData.filter(data => !data.compare_to_field_name).length;
            const confirmationInputsNeeded          : number                    = productUserData.filter(data => data.compare_to_field_name).length;
            const currentConfirmationsAmount        : number                    = inputConfirmations.length;
            const allParamsNeeded                   : boolean                   = expectedAmountOfUserDataNeeded === params.length;
            const allConfirmationsNeeded            : boolean                   = currentConfirmationsAmount === confirmationInputsNeeded;
            const errorsOnInputFields               : TopupTextFieldErrorData[] = topupTextFieldErrorData.filter(textField => textField.error);
            const hasErrors                         : boolean                   = errorsOnInputFields.length > 0;
            return !(allParamsNeeded && allConfirmationsNeeded && !hasErrors);
        }
    }

    useEffect(function productDataEffect() {

        // initial data setup
        if(history && history.location && history.location.state) {
            const state: ProductsState = history.location.state;

            // data for auto selected product tabs
            const autoSelectedProductID: GetDebtInfoUserData[] = (state.products.length === 1 && state.products[0].get_debt_info) ? state.products[0].get_debt_info.user_data.filter(data => data.is_id_tab) : [];
            const autoSelectedProductIDAlternative: GetDebtInfoUserData[] = (state.products.length === 1 && state.products[0].get_debt_info) ? state.products[0].get_debt_info.user_data.filter(data => data.is_id_alternative_tab) : [];
            const autoSelectedOtherProducts: GetDebtInfoUserData[] = (state.products.length === 1 && state.products[0].get_debt_info) ? state.products[0].get_debt_info.user_data.filter(data => !data.is_id_tab && !data.is_id_alternative_tab) : [];
            const autoSelectedProductIDTabs = [...autoSelectedProductID, ...autoSelectedProductIDAlternative];
            const autoSelectedProductIDTabsInput = [...autoSelectedProductID, ...autoSelectedOtherProducts];
            const autoSelectedProductIDAlternativeTabsInput = [...autoSelectedProductIDAlternative, ...autoSelectedOtherProducts];

            // data for user selected product tabs
            const userSelectedProductID: GetDebtInfoUserData[] = ((userSelectedProduct && userSelectedProduct.get_debt_info) ? userSelectedProduct.get_debt_info.user_data.filter(data => data.is_id_tab) : []);
            const userSelectedProductIDAlternative: GetDebtInfoUserData[] = ((userSelectedProduct && userSelectedProduct.get_debt_info) ? userSelectedProduct.get_debt_info.user_data.filter(data => data.is_id_alternative_tab) : []);
            const userSelectedOtherProducts: GetDebtInfoUserData[] = ((userSelectedProduct && userSelectedProduct.get_debt_info) ? userSelectedProduct.get_debt_info.user_data.filter(data => !data.is_id_tab && !data.is_id_alternative_tab) : []);
            const userSelectedProductIDTabs = [...userSelectedProductID, ...userSelectedProductIDAlternative];
            const userSelectedProductIDTabsInput = [...userSelectedProductID, ...userSelectedOtherProducts];
            const userSelectedProductIDAlternativeTabsInput = [...userSelectedProductIDAlternative, ...userSelectedOtherProducts];

            // check if need show selection tabs
            const autoSelectedProductNeedTabs: boolean = autoSelectedProduct && autoSelectedProduct.get_debt_info && autoSelectedProduct.get_debt_info.user_data.some(data => data.is_id_tab === true);
            const userSelectedProductNeedTabs: boolean = userSelectedProduct && userSelectedProduct.get_debt_info && userSelectedProduct.get_debt_info.user_data.some(data => data.is_id_tab === true);

            setInitialData(state);
            setIsAutoSelectedProduct(state.products.length === 1);
            setAutoSelectedProduct(state.products[0]);
            setAutoSelectedProductNeedDebtQuery(Boolean(state.products.length === 1 && state.products[0].get_debt_info));
            setAutoSelectedProductIDTabs(autoSelectedProductIDTabs);
            setAutoSelectedProductIDTabsInput(autoSelectedProductIDTabsInput);
            setAutoSelectedProductIDAlternativeTabsInput(autoSelectedProductIDAlternativeTabsInput);
            setShowTabsForDebtQuery(autoSelectedProductNeedTabs || userSelectedProductNeedTabs);
            setUserAutoSelectedProductIDTabs(userSelectedProductIDTabs);
            setUserAutoSelectedProductIDTabsInput(userSelectedProductIDTabsInput);
            setUserAutoSelectedProductIDAlternativeTabsInput(userSelectedProductIDAlternativeTabsInput);
            setUserSelectedProductNeedDebtQuery(Boolean((userSelectedProduct && userSelectedProduct.get_debt_info)));
            setProductImage(userSelectedProduct ? userSelectedProduct.design.background_image : state.categoryCover);
        }
    }, [history, initialData, autoSelectedProduct, userSelectedProduct]);

    const themeOverrides: Theme  =   createMuiTheme({
        palette: {
            primary: {
                main: `${(userSelectedProduct && 
                    userSelectedProduct.design.background_color !== '#FFFFFF' &&
                    userSelectedProduct.design.background_color !== '#ffffff' && 
                    userSelectedProduct.design.background_color) || (autoSelectedProduct && 
                    autoSelectedProduct.design.background_color !== '#FFFFFF' && 
                    autoSelectedProduct.design.background_color !== '#ffffff' && 
                    autoSelectedProduct.design.background_color) || '#28c339'}`,
            }
        }
    });

    return(
        <MuiThemeProvider theme={themeOverrides}>
            <div style={{ width: '100%', maxWidth: 480, marginLeft: 'auto', marginRight: 'auto' }} data-testid='product-screen'>
                <ProductAppBar
                    userSelectedProduct={userSelectedProduct}
                    autoSelectedProduct={autoSelectedProduct}
                    initialData={initialData}
                />

                <section style={{
                    width: '90%',
                    display:'flex',
                    flexFlow: 'column nowrap',
                    alignItems: 'center',
                    margin: '0 auto',
                    padding: 16
                }}>
                    {
                        <Paper
                            style={{
                                display: 'flex',
                                width: '100%',
                                color: `${(userSelectedProduct && userSelectedProduct.design.font_color) || (autoSelectedProduct && autoSelectedProduct.design.font_color)}`,
                                flexFlow: 'column nowrap',
                                justifyContent: 'space-evenly',
                                alignItems: 'center',
                                alignContent: 'center',
                                paddingBottom: 16
                            }}
                            elevation={4}>
                            {
                                // topup screen
                                !showLoadingScreen &&
                                !errorStatusCode &&
                                showTopupScreen && (
                                    <>
                                        <div style={{ marginBottom: 12, width: '100%',  background: `${(userSelectedProduct && userSelectedProduct.design.background_color) || (autoSelectedProduct && autoSelectedProduct.design.background_color) || '#ff0000'}` }}>
                                            <picture>
                                                <img
                                                    style={{
                                                        maxWidth: '100%',
                                                        display: 'block',
                                                        margin: '0 auto'
                                                    }}
                                                    data-testid='product-image'
                                                    src={productImage}
                                                    alt='product'
                                                />
                                            </picture>
                                        </div>

                                        {
                                            // show debt query screen
                                            (autoSelectedProductNeedDebtQuery || userSelectedProductNeedDebtQuery) && (
                                                <div data-testid='debt-query-screen' style={{ width: '90%', margin: '0 auto' }}>
                                                    {
                                                        showTabsForDebtQuery && (
                                                            <>
                                                                <Tabs
                                                                    value={tabsValue}
                                                                    onChange={handleTabsChange}
                                                                    indicatorColor='primary'
                                                                    textColor='primary'
                                                                    variant='fullWidth'
                                                                    aria-label='user data'
                                                                    data-testid='debt-query-screen-tabs'>
                                                                    {
                                                                        isAutoSelectedProduct && autoSelectedProductIDTabs && autoSelectedProductIDTabs.map(data => {
                                                                            return(
                                                                                <Tab data-testid={`debt-query-screen-${data.field_name}-tab`} key={data.field_name} label={data.field_title} id={`full-width-tab-${data.field_name}`} aria-controls={`full-width-tabpanel-${data.field_name}`}/>
                                                                            );
                                                                        })
                                                                    }
                                                                    {
                                                                        !isAutoSelectedProduct && userSelectedProductIDTabs && userSelectedProductIDTabs.map(data => {
                                                                            return(
                                                                                <Tab data-testid={`debt-query-screen-${data.field_name}-tab`} key={data.field_name} label={data.field_title} id={`full-width-tab-${data.field_name}`} aria-controls={`full-width-tabpanel-${data.field_name}`}/>
                                                                            );
                                                                        })
                                                                    }
                                                                </Tabs>
                                                                <SwipeableViews
                                                                    axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
                                                                    index={tabsValue}
                                                                    onChangeIndex={handleTabsChangeIndex}
                                                                    data-testid='debt-query-screen-tabs-panels'>
                                                                    <TabPanel
                                                                        value={tabsValue}
                                                                        index={0}
                                                                        dir={theme.direction}
                                                                        data-testid='debt-query-screen-tabs-panels-id'>
                                                                        {
                                                                            isAutoSelectedProduct && autoSelectedProductIDTabsInput && autoSelectedProductIDTabsInput.map(function productStateGetDebtInfoUserData(userData) {
                                                                                const errorData = topupTextFieldErrorData && topupTextFieldErrorData.filter(data => (data.fieldName === userData.field_name));
                                                                                return(
                                                                                    <React.Fragment key={userData.field_name}>
                                                                                        <FormControl fullWidth>
                                                                                            <TextField
                                                                                                error={errorData && errorData.length > 0 && errorData[0].error}
                                                                                                style={{
                                                                                                    marginBottom: 18
                                                                                                }}
                                                                                                type={userData.field_type}
                                                                                                required
                                                                                                fullWidth
                                                                                                inputProps={{
                                                                                                    'data-testid': `debt-query-screen-${userData.field_name}-text-field`
                                                                                                }}
                                                                                                label={`${userData.field_title}`}
                                                                                                onChange={handleTopupTextField(userData)}
                                                                                            />
                                                                                            <Typography variant='body2'>
                                                                                                { errorData && errorData.length > 0 && errorData[0].errorMessage }
                                                                                            </Typography>
                                                                                        </FormControl>
                                                                                    </React.Fragment>
                                                                                );
                                                                            })
                                                                        }
                                                                        {
                                                                            !isAutoSelectedProduct && userSelectedProductIDTabsInput && userSelectedProductIDTabsInput.map(function productStateGetDebtInfoUserData(userData) {
                                                                                const errorData = topupTextFieldErrorData && topupTextFieldErrorData.filter(data => (data.fieldName === userData.field_name));
                                                                                return(
                                                                                    <React.Fragment key={userData.field_name}>
                                                                                        <FormControl fullWidth>
                                                                                            <TextField
                                                                                                error={errorData && errorData.length > 0 && errorData[0].error}
                                                                                                style={{
                                                                                                    marginBottom: 18
                                                                                                }}
                                                                                                type={userData.field_type}
                                                                                                required
                                                                                                fullWidth
                                                                                                inputProps={{
                                                                                                    'data-testid': `debt-query-${userData.field_name}-input`
                                                                                                }}
                                                                                                label={`${userData.field_title}`}
                                                                                                onChange={handleTopupTextField(userData)}
                                                                                            />
                                                                                            <Typography variant='body2' style={{color: 'red'}}>
                                                                                                { errorData && errorData.length > 0 && errorData[0].errorMessage }
                                                                                            </Typography>
                                                                                        </FormControl>
                                                                                    </React.Fragment>
                                                                                );
                                                                            })
                                                                        }
                                                                    </TabPanel>
                                                                    <TabPanel
                                                                        value={tabsValue}
                                                                        index={1}
                                                                        dir={theme.direction}
                                                                        data-testid='debt-query-screen-tabs-panels-alternative-id'>
                                                                        {
                                                                            isAutoSelectedProduct && autoSelectedProductIDAlternativeTabsInput && autoSelectedProductIDAlternativeTabsInput.map(function productStateGetDebtInfoUserData(userData) {
                                                                                const errorData = topupTextFieldErrorData && topupTextFieldErrorData.filter(data => (data.fieldName === userData.field_name));
                                                                                return(
                                                                                    <React.Fragment key={userData.field_name}>
                                                                                        <FormControl fullWidth>
                                                                                            <TextField
                                                                                                error={errorData && errorData.length > 0 && errorData[0].error}
                                                                                                style={{
                                                                                                    marginBottom: 18
                                                                                                }}
                                                                                                type={userData.field_type}
                                                                                                required
                                                                                                fullWidth
                                                                                                inputProps={{
                                                                                                    'data-testid': `debt-query-screen-${userData.field_name}-input`
                                                                                                }}
                                                                                                label={`${userData.field_title}`}
                                                                                                onChange={handleTopupTextField(userData)}
                                                                                            />
                                                                                            <Typography variant='body2' style={{color: 'red'}}>
                                                                                                { errorData && errorData.length > 0 && errorData[0].errorMessage }
                                                                                            </Typography>
                                                                                        </FormControl>
                                                                                    </React.Fragment>
                                                                                );
                                                                            })
                                                                        }
                                                                        {
                                                                            !isAutoSelectedProduct && userSelectedProductIDAlternativeTabsInput && userSelectedProductIDAlternativeTabsInput.map(function productStateGetDebtInfoUserData(userData) {
                                                                                const errorData = topupTextFieldErrorData && topupTextFieldErrorData.filter(data => (data.fieldName === userData.field_name));
                                                                                return(
                                                                                    <React.Fragment key={userData.field_name}>
                                                                                        <FormControl fullWidth>
                                                                                            <TextField
                                                                                                error={errorData && errorData.length > 0 && errorData[0].error}
                                                                                                style={{
                                                                                                    marginBottom: 18
                                                                                                }}
                                                                                                type={userData.field_type}
                                                                                                required
                                                                                                fullWidth
                                                                                                inputProps={{
                                                                                                    'data-testid': `debt-query-${userData.field_name}-input`
                                                                                                }}
                                                                                                label={`${userData.field_title}`}
                                                                                                onChange={handleTopupTextField(userData)}
                                                                                            />
                                                                                            <Typography variant='body2' style={{color: 'red'}}>
                                                                                                { errorData && errorData.length > 0 && errorData[0].errorMessage }
                                                                                            </Typography>
                                                                                        </FormControl>
                                                                                    </React.Fragment>
                                                                                );
                                                                            })
                                                                        }
                                                                    </TabPanel>
                                                                </SwipeableViews>
                                                            </>
                                                        )
                                                    }
                                                    {
                                                        !showTabsForDebtQuery && isAutoSelectedProduct && autoSelectedProductIDAlternativeTabsInput && autoSelectedProductIDAlternativeTabsInput.map(function productStateGetDebtInfoUserData(userData) {
                                                            const errorData = topupTextFieldErrorData && topupTextFieldErrorData.filter(data => (data.fieldName === userData.field_name));
                                                            return(
                                                                <React.Fragment key={userData.field_name}>
                                                                    <FormControl fullWidth data-testid={'autoselected-debt-query-screen-input-text'} style={{flexDirection: 'inherit'}}>
                                                                        <TextField
                                                                            error={errorData && errorData.length > 0 && errorData[0].error}
                                                                            style={{
                                                                                marginBottom: 18,
                                                                                flexDirection: 'inherit'
                                                                            }}
                                                                            type={userData.field_type}
                                                                            required
                                                                            fullWidth
                                                                            inputProps={{
                                                                                'data-testid': `debt-query-${userData.field_name}-input`
                                                                            }}
                                                                            label={`${userData.field_title}`}
                                                                            onChange={handleTopupTextField(userData)}
                                                                        />
                                                                        {
                                                                            userData.helper_image && (
                                                                                <InfoModal modalUrl={userData.helper_image}/>
                                                                            )
                                                                        }
                                                                        <Typography variant='body2' style={{color: 'red'}}>
                                                                            { errorData && errorData.length > 0 && errorData[0].errorMessage }
                                                                        </Typography>
                                                                    </FormControl>
                                                                </React.Fragment>
                                                            );
                                                        })
                                                    }
                                                    {
                                                        !showTabsForDebtQuery && !isAutoSelectedProduct && userSelectedProductIDAlternativeTabsInput && userSelectedProductIDAlternativeTabsInput.map(function productStateGetDebtInfoUserData(userData) {
                                                            const errorData = topupTextFieldErrorData && topupTextFieldErrorData.filter(data => (data.fieldName === userData.field_name));
                                                            return(
                                                                <React.Fragment key={userData.field_name}>
                                                                    <FormControl fullWidth data-testid={'user-selected-debt-query-screen-input-text'} style={{flexDirection: 'inherit'}}>
                                                                        <TextField
                                                                            error={errorData && errorData.length > 0 && errorData[0].error}
                                                                            style={{
                                                                                marginBottom: 18,
                                                                                flexDirection: 'inherit'
                                                                            }}
                                                                            type={userData.field_type}
                                                                            required
                                                                            fullWidth
                                                                            inputProps={{
                                                                                'data-testid': `debt-query-${userData.field_name}-input`
                                                                            }}
                                                                            label={`${userData.field_title}`}
                                                                            onChange={handleTopupTextField(userData)}
                                                                        />
                                                                        {
                                                                            userData.helper_image && (
                                                                                <InfoModal modalUrl={userData.helper_image}/>
                                                                            )
                                                                        }
                                                                        <Typography variant='body2' style={{color: 'red'}}>
                                                                            { errorData && errorData.length > 0 && errorData[0].errorMessage }
                                                                        </Typography>
                                                                    </FormControl>
                                                                </React.Fragment>
                                                            );
                                                        })
                                                    }
                                                </div>
                                            )
                                        }
                                        {
                                            // auto seleccted single product topup screen
                                            (!userSelectedProduct && initialData && initialData.products.length === 1) &&
                                            !initialData.products[0].get_debt_info && (
                                                <div style={{ width: '90%', margin: '0 auto' }}>
                                                    {
                                                        initialData && initialData.products[0].create_order_info.user_data.map(function productStateGetDebtInfoUserData(userData) {
                                                            const errorData = topupTextFieldErrorData && topupTextFieldErrorData.filter(data => (data.fieldName === userData.field_name));
                                                            return(
                                                                <React.Fragment key={userData.field_name}>
                                                                    {
                                                                        userData && !userData.amounts_allowed && (
                                                                            <FormControl fullWidth style={{flexDirection: 'inherit'}}>
                                                                                <TextField
                                                                                    error={errorData && errorData.length > 0 && errorData[0].error}
                                                                                    style={{
                                                                                        marginTop: 6,
                                                                                        marginBottom: 6,
                                                                                        flexDirection: 'inherit'
                                                                                    }}
                                                                                    type={userData.field_type}
                                                                                    required
                                                                                    fullWidth
                                                                                    inputProps={{
                                                                                        'data-testid': `topup-${userData.field_name}-input`
                                                                                    }}
                                                                                    label={`${userData.field_title}`}
                                                                                    onChange={handleTopupTextField(userData)}
                                                                                />
                                                                                {
                                                                                    userData.helper_image && (
                                                                                        <InfoModal modalUrl={userData.helper_image}/>
                                                                                    )
                                                                                }
                                                                                <Typography variant='body2' style={{color: 'red'}}>
                                                                                    { errorData && errorData.length > 0 && errorData[0].errorMessage }
                                                                                </Typography>
                                                                            </FormControl>
                                                                        )
                                                                    }
                                                                    {
                                                                        userData && userData.amounts_allowed && (
                                                                            <FormControl fullWidth>
                                                                                <FormHelperText style={{color: 'white'}}>Selecciona un monto a recargar:</FormHelperText>
                                                                                <Select
                                                                                    style={{
                                                                                        width: '100%'
                                                                                    }}
                                                                                    variant='standard'
                                                                                    native
                                                                                    onChange={event => {
                                                                                        event.preventDefault();
                                                                                        const paramName: string = userData && userData.values && userData.values.length > 1 ? userData.values.filter(data => data.value === 'self')[0].name : userData.field_name;
                                                                                        setParams(prevParams => {
                                                                                            const otherParams: Params[] = prevParams.filter(param => param.name !== paramName);
                                                                                            return [
                                                                                                ...otherParams,
                                                                                                {
                                                                                                    name: paramName,
                                                                                                    value: String(event.target.value)
                                                                                                }
                                                                                            ];
                                                                                        });
                                                                                    }}
                                                                                    inputProps={{
                                                                                        name: 'age',
                                                                                        id: 'filled-age-native-simple',
                                                                                    }}>
                                                                                    <option value={''}/>
                                                                                    {
                                                                                        userData.amounts_allowed.map((amount) => (
                                                                                            <option
                                                                                                key={amount}
                                                                                                value={amount}>
                                                                                                {amount}
                                                                                            </option>
                                                                                        ))
                                                                                    }
                                                                                </Select>
                                                                                <Typography variant='body2' style={{color: 'red'}}>
                                                                                    { errorData && errorData.length > 0 && errorData[0].errorMessage }
                                                                                </Typography>
                                                                            </FormControl>
                                                                        )
                                                                    }
                                                                </React.Fragment>
                                                            );
                                                        })
                                                    }
                                                </div>
                                            )
                                        }
                                        {
                                            // multiple products topup screen
                                            userSelectedProduct &&
                                            !userSelectedProduct.get_debt_info && (
                                                <div style={{ width: '90%'}} data-testid='selected-product-topup-screen'>
                                                    {
                                                        userSelectedProduct && userSelectedProduct.create_order_info.user_data.map(function userSelectedProductCreateOrderInfoUserDataMap(userData) {
                                                            const errorData = topupTextFieldErrorData && topupTextFieldErrorData.filter(data => (data.fieldName === userData.field_name));
                                                            return(
                                                                <React.Fragment key={userData.field_name}>
                                                                    <FormControl fullWidth>
                                                                        <TextField
                                                                            error={errorData && errorData.length > 0 && errorData[0].error}
                                                                            style={{
                                                                                marginBottom: 18
                                                                            }}
                                                                            type={userData.field_type}
                                                                            required
                                                                            fullWidth
                                                                            inputProps={{
                                                                                'data-testid': `selected-product-${userData.field_name}-input`
                                                                            }}
                                                                            label={`${userData.field_title}`}
                                                                            onChange={handleTopupTextField(userData)}
                                                                        />
                                                                        <Typography variant='body2' style={{color: 'red'}}>
                                                                            { errorData && errorData.length > 0 && errorData[0].errorMessage }
                                                                        </Typography>
                                                                    </FormControl>
                                                                </React.Fragment>
                                                            );
                                                        })
                                                    }
                                                </div>
                                            )
                                        }
                                        {
                                            // description and topup button
                                            <div style={{ width: '90%' }}>
                                                {
                                                    // selected product description
                                                    userSelectedProduct && userSelectedProduct.description && (
                                                        <Typography
                                                            data-testid={`product-${userSelectedProduct.id}-description`}
                                                            style={{ fontSize: 12, margin: '8px 0' }}
                                                            variant={'body1'}
                                                            color='textPrimary'>
                                                            { userSelectedProduct.description }
                                                        </Typography>
                                                    )
                                                }
                                                {
                                                    // auto selected product description
                                                    !userSelectedProduct && initialData && initialData.products.length === 1 && (
                                                        <Typography
                                                            data-testid={`product-${initialData.products[0].id}-description`}
                                                            style={{ fontSize: 12, margin: '8px 0' }}
                                                            variant={'body1'}
                                                            color='textPrimary'>
                                                            { initialData.products[0].description }
                                                        </Typography>
                                                    )
                                                }
                                                {
                                                    // show only on debt query screen
                                                    ((initialData && initialData.products.length === 1 && initialData.products[0].get_debt_info) ||
                                                        (userSelectedProduct && userSelectedProduct.get_debt_info)) && (
                                                        <Button
                                                            style={{
                                                                marginTop: 8,
                                                                width: '100%'
                                                            }}
                                                            variant='contained'
                                                            color='primary'
                                                            size='large'
                                                            disabled={handleTopupButtonDisabledState()}
                                                            data-testid='debt-query-button'
                                                            onClick={handleDebtQueryButton}>
                                                            Consultar
                                                        </Button>
                                                    )
                                                }
                                                {
                                                    // show while no debt query screen
                                                    ((initialData && initialData.products.length === 1 && !initialData.products[0].get_debt_info) ||
                                                        (userSelectedProduct && !userSelectedProduct.get_debt_info)) && (
                                                        <Button
                                                            style={{
                                                                marginTop: 8,
                                                                width: '100%',

                                                            }}
                                                            variant='contained'
                                                            color='primary'
                                                            size='large'
                                                            disabled={handleTopupButtonDisabledState()}
                                                            data-testid='topup-button'
                                                            onClick={handleTopupButton}>
                                                            Cargar
                                                        </Button>
                                                    )
                                                }
                                            </div>
                                        }
                                    </>
                                )
                            }
                            {
                                showDebtQuerySuccessResponseScreen && debtQuerySuccessResponse && (
                                    <div data-testid='debt-query-response-screen' style={{
                                        width: '100%',
                                        margin: '0 auto',
                                        display: 'flex',
                                        flexFlow: 'column nowrap',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        alignContent: 'center'
                                    }}>
                                        <section style={{
                                            width: '92%',
                                            padding: 10,
                                            paddingBottom: 0,
                                            display: 'flex',
                                            flexFlow: 'column nowrap',
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                            alignContent: 'center'
                                        }}>
                                            {
                                                // no debt screen
                                                debtQuerySuccessResponse && debtQuerySuccessResponse.debt_result && debtQuerySuccessResponse.debt_result.length === 1 && debtQuerySuccessResponse.debt_result.some(response => response.amount === 0) && (
                                                    <section style={{
                                                        display: 'flex',
                                                        flexFlow: 'column nowrap',
                                                        justifyContent: 'center',
                                                        alignItems: 'center',
                                                        alignContent: 'center'
                                                    }}>
                                                        <img style={{ width: 156, margin: '18px auto'}} src='https://i.ibb.co/X5j4Ky7/bill-1.png' alt='No hay deuda'/>
                                                        <Typography style={{color: 'black', fontSize: 14, textAlign: 'center', margin: '6px auto'}}>Este número no presenta pagos pendientes.</Typography>
                                                        <Button
                                                            fullWidth
                                                            data-testid='debt-query-topup-button'
                                                            style={{
                                                                width: 260,
                                                                color: 'white',
                                                                margin: '36px auto 8px auto'
                                                            }}
                                                            variant='contained'
                                                            color='primary'
                                                            onClick={() => history.goBack()}>
                                                            Volver
                                                        </Button>
                                                    </section>
                                                )
                                            }
                                            {
                                                // debt screen
                                                debtQuerySuccessResponse && debtQuerySuccessResponse.debt_result && debtQuerySuccessResponse.debt_result.some(response => !(response.amount === 0)) && (
                                                    <React.Fragment>
                                                        <Typography
                                                            style={{
                                                                margin: '0 0 10px 0',
                                                                fontSize: 20,
                                                                color: 'slategray',
                                                                textAlign: 'center',
                                                                opacity: '52%',
                                                                marginBottom: 10
                                                            }}
                                                            variant='h6'
                                                            gutterBottom
                                                        >
                                                            You are paying {`$${debtQueryAmount || '0'}`} for Service No. {`${initialData.products[0].id}`} for {`${initialData.categoryTitle}`}
                                                        </Typography>
                                                        <Paper
                                                            elevation={0}
                                                            style={{
                                                                width: '90%',
                                                                marginLeft: 'auto',
                                                                marginRight: 'auto',
                                                                padding: 20,
                                                                borderRadius: '5%'
                                                            }}
                                                        >
                                                        {
                                                            debtQuerySuccessResponse.debt_result.map((response, key) => response.amount !== 0 && (
                                                                <React.Fragment key={key}>
                                                                    <GridList cellHeight={60} cols={5} style={{marginTop: 30}}>
                                                                        <GridListTile cols={4}>
                                                                            <Typography
                                                                                style={{
                                                                                    margin: '5px 0 10px 0',
                                                                                    fontSize: 14,
                                                                                    color: '#ACACAC',
                                                                                }}
                                                                                variant='h6'
                                                                                gutterBottom
                                                                            >
                                                                                Select to Pay
                                                                            </Typography>
                                                                        </GridListTile>
                                                                        <GridListTile cols={1}>
                                                                            <Switch
                                                                                data-testid={`debt-query-${response.id}-switch`}
                                                                                style={{
                                                                                    marginRight: 10
                                                                                }}
                                                                                checked={queryDebtIdChecked === response.id}
                                                                                onChange={handleDebtQuerySwitchOnChange(response.amount, response.id, response.id_transaction, response.debt_number)}
                                                                                color='primary'
                                                                            />
                                                                        </GridListTile>
                                                                    </GridList>
                                                                    <GridList cellHeight={60} cols={4}>
                                                                        <GridListTile cols={2}>
                                                                            <Typography
                                                                                style={{
                                                                                    margin: '0 0 10px 0',
                                                                                    fontSize: 14,
                                                                                    color: '#ACACAC',
                                                                                }}
                                                                                variant='h6'
                                                                                gutterBottom
                                                                            >
                                                                                Description
                                                                            </Typography>
                                                                        </GridListTile>
                                                                        <GridListTile cols={2}>
                                                                            <Typography
                                                                                style={{
                                                                                    margin: '0 0 10px 0',
                                                                                    fontSize: 14,
                                                                                    color: '#011238',
                                                                                    textAlign: 'right'
                                                                                }}
                                                                                variant='h6'
                                                                                gutterBottom
                                                                            >
                                                                                {`${response.printLine}`}
                                                                            </Typography>
                                                                        </GridListTile>
                                                                    </GridList>
                                                                    <GridList cellHeight={60} cols={4} style={{borderBottom: 'dashed 1px #ACACAC'}}>
                                                                        <GridListTile cols={2}>
                                                                            <Typography
                                                                                style={{
                                                                                    margin: '0 0 10px 0',
                                                                                    fontSize: 14,
                                                                                    color: '#ACACAC',
                                                                                }}
                                                                                variant='h6'
                                                                                gutterBottom
                                                                            >
                                                                                Amount
                                                                            </Typography>
                                                                        </GridListTile>
                                                                        <GridListTile cols={2}>
                                                                            <Typography
                                                                                style={{
                                                                                    margin: '0 0 10px 0',
                                                                                    fontSize: 14,
                                                                                    color: '#011238',
                                                                                    textAlign: 'right',
                                                                                }}
                                                                                variant='h6'
                                                                                gutterBottom
                                                                            >
                                                                                {`$${response.amount}`}
                                                                            </Typography>
                                                                        </GridListTile>
                                                                    </GridList>
                                                                </React.Fragment>
                                                            ))
                                                        }
                                                        <GridList cellHeight={60} cols={4} style={{marginTop: 35}}>
                                                            <GridListTile cols={2}>
                                                                <Typography
                                                                    style={{
                                                                        margin: '0 0 10px 0',
                                                                        fontSize: 14,
                                                                        color: '#ACACAC',
                                                                    }}
                                                                    variant='h6'
                                                                    gutterBottom
                                                                >
                                                                    Total Pay
                                                                </Typography>
                                                            </GridListTile>
                                                            <GridListTile cols={2}>
                                                                <Typography
                                                                    style={{
                                                                        margin: '0 0 10px 0',
                                                                        fontSize: 14,
                                                                        color: '#011238',
                                                                        textAlign: 'right',
                                                                    }}
                                                                    variant='h6'
                                                                    gutterBottom
                                                                >
                                                                    {`$${debtQueryAmount || '0'}`}
                                                                </Typography>
                                                            </GridListTile>
                                                        </GridList>
                                                        </Paper>
                                                    </React.Fragment>
                                                )
                                            }
                                            {
                                                debtQuerySuccessResponse && debtQuerySuccessResponse.debt_result && debtQuerySuccessResponse.debt_result.some(response => !(response.amount === 0)) && (
                                                    <Button
                                                        style={{
                                                            marginTop: 8,
                                                            width: '100%',
                                                            color: 'white',
                                                        }}
                                                        variant='contained'
                                                        color='primary'
                                                        size='large'
                                                        data-testid='debt-query-topup-button'
                                                        disabled={!(queryDebtIdChecked && debtQueryAmount)}
                                                        onClick={handleDebtQueryTopupButton}
                                                    >
                                                        Pay bill
                                                    </Button>
                                                )
                                            }
                                        </section>
                                    </div>
                                )
                            }
                            {
                                showLoadingScreen && (
                                    <div data-testid='loading-screen' className={loadingContainer}>
                                        <CircularProgress
                                            style={{ color: green[500] }}
                                            thickness={4}
                                            size={60}/>
                                    </div>
                                )
                            }
                            {
                                showSuccessScreen && (
                                    <div data-testid='success-screen' style={{ display: 'flex', flexFlow: 'column nowrap', justifyContent: 'center', alignItems: 'center' }}>
                                        <DoneAllIcon style={{ color: '#28c339', fontSize: 124, margin:16 }}/>
                                        <Typography
                                            style={{ fontSize: 14, color: 'black' }}
                                            align='center'
                                            variant='body1'>
                                            <strong>Name: </strong> { dataName }
                                        </Typography>allDataProduct
                                        {
                                            allDataProduct && (allDataProduct.payment_id || allDataProduct.reference) && (
                                            <Typography
                                                style={{fontSize: 14, color: 'black'}}
                                                align='center'
                                                variant='body1'>
                                                <strong>ID Client: </strong> {allDataProduct.payment_id || allDataProduct.reference}
                                            </Typography>
                                        )}
                                        {
                                            allDataProduct && allDataProduct.number && (
                                                <Typography
                                                    style={{fontSize: 14, color: 'black'}}
                                                    align='center'
                                                    variant='body1'>
                                                    <strong>Numero: </strong> {allDataProduct.number}
                                                </Typography>
                                            )}
                                        {
                                            dataEmail && (
                                            <Typography
                                            style={{ fontSize: 14, color: 'black' }}
                                            align='center'
                                            variant='body1'>
                                            <strong>Email: </strong> { dataEmail }
                                            </Typography>
                                        )}

                                        <Typography
                                            style={{ fontSize: 14, color: 'black' }}
                                            align='center'
                                            variant='body1'>
                                            <strong>Amount: $</strong> { (debtQueryAmount || allDataProduct) && allDataProduct.amount && allDataProduct.amount}
                                        </Typography>
                                        <Typography
                                            style={{ fontSize: 14, color: 'black' }}
                                            align='center'
                                            variant='body1'>
                                            <strong>UID: </strong> { dataUid }
                                        </Typography>
                                    </div>
                                )
                            }
                            {
                                showErrorScreen && (
                                    <div data-testid='error-screen' style={{ display: 'flex', flexFlow: 'column nowrap', justifyContent: 'center', alignItems: 'center' }}>
                                        <ErrorIcon style={{ color: 'red', fontSize: 124, margin:16 }}/>
                                        <Typography
                                            style={{ fontSize: 24, color: 'black' }}
                                            align='center'
                                            variant='body1'>
                                            { errorStatusCode }
                                        </Typography>
                                        <Typography
                                            style={{ fontSize: 14, color: 'black' }}
                                            align='center'
                                            variant='body1'>
                                            { errorStatusMessage }
                                        </Typography>
                                    </div>
                                )
                            }
                        </Paper>
                    }
                </section>
            </div>
        </MuiThemeProvider>
    );
};

export default Product;

const validateRut = function validateRut(rutString: string) {
    const normalizedString: string = rutString.trim().replace(/ /g, '').replace(/-/g, '').replace(/\./g, '');
    const verificatorDigit: string = normalizedString.slice(-1);
    const rutNumbers: string = normalizedString.slice(0, -1);
    const reversedRutNumbers: string = rutNumbers.split('').reverse().join('');
    const arrayOfRutNumbers: string[] = Array.from(reversedRutNumbers);
    const arrayOfBaseSerialNumbers: number[] = [2, 3, 4, 5, 6, 7];
    const quantityOfNumbersNeeded: number = arrayOfRutNumbers.length - arrayOfBaseSerialNumbers.length;
    const arrayOfSerialNumbersToAdd: number[] = arrayOfBaseSerialNumbers.slice(0, quantityOfNumbersNeeded);
    const arrayOfSerialNumbersToMultiply = [
        ...arrayOfBaseSerialNumbers,
        ...arrayOfSerialNumbersToAdd
    ];
    const arrayOfNumbers: number[] = arrayOfRutNumbers.map(function rutNumber(rutNumber, index){
        return Number(rutNumber) * arrayOfSerialNumbersToMultiply[index];
    });
    const sumArrayOfNumbers: number = arrayOfNumbers && arrayOfNumbers.length > 0 && arrayOfNumbers.reduce(function reduceArrayOfNumbers(accumulator, currentValue) {
        return accumulator + currentValue;
    });
    const module: number = 11;
    const reminder: number = sumArrayOfNumbers / module;
    const nextReminder: number = sumArrayOfNumbers - (11 * Math.trunc(reminder));
    const result: number = module - nextReminder;
    const verificator: string = (result !== 10) && (result !== 11)
        ? result.toString()
        : (result === 10)
            ? 'K'
            : '0';
    return (verificator === verificatorDigit.toString().toUpperCase());
};
const parseRutNumber = function(validRut: string) {
    const verificatorDigit: string = validRut.slice(-1);
    const rutNumbers: string = validRut.slice(0, -1);
    const expectedScore: string = rutNumbers.slice(-1);
    const itHaveAScoreSymbol: boolean = expectedScore === '-';
    return itHaveAScoreSymbol
        ? validRut
        : `${rutNumbers}-${verificatorDigit}`;
};