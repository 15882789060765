import {useHistory, useLocation} from 'react-router-dom';
import React, {useEffect, useState}     from 'react';

import {Category as CategoryTypes, Product as ProductTypes} from "../../CyclopsTypes";

import {
    makeStyles,
    createStyles }      from '@material-ui/core';
import List             from '@material-ui/core/List';
import ListItem         from '@material-ui/core/ListItem';
import ListItemText     from '@material-ui/core/ListItemText';
import ListItemAvatar   from '@material-ui/core/ListItemAvatar';
import Paper            from '@material-ui/core/Paper';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import IconButton       from "@material-ui/core/IconButton";
import CircularProgress from "@material-ui/core/CircularProgress";
import green from "@material-ui/core/colors/green";

const useStyles = makeStyles((theme) => createStyles({
    container: {
        backgroundColor: '#F9F9F9',
        width: '100vw',
        [theme.breakpoints.down(611)]: {
            paddingTop: '60px',
        },
        [theme.breakpoints.up(612)]: {
            paddingLeft: '25vw',
            paddingRight: '25vw',
            paddingTop: '70px',
            backgroundColor: 'white',
            width: '50vw',
        },
        [theme.breakpoints.up(1000)]: {
            paddingLeft: '35vw',
            paddingTop: '70px',
            paddingRight: '35vw',
            backgroundColor: 'white',
            width: '400px',
        },
    },
    singleProduct: {
        marginTop: 16,
        backgroundColor: '#F9F9F9',
        paddingLeft: 16,
        paddingRight: 24,
        clear: 'both',
        [theme.breakpoints.up(600)]: {
            backgroundColor: 'white',
        },
    },

    listItem: {
        backgroundColor: 'white',
        borderRadius: 10,
        marginBottom: 5,
    },
    list: {
        padding: 0
    },
    icons: {
        borderRadius: 10,
        width: '50px',
        height: '50px'
    },
    loadingContainer: {
        display: 'flex',
        flexFlow: 'column nowrap',
        alignItems: 'center',
        background: 'white',
        paddingTop: '25%',
        paddingBottom: '25%'
    },
    goBackImg: {
        width: 30,
        height: 'auto',
        float: 'right',
        marginBottom: 15,
        marginTop: 'auto',
        marginLeft: 10,
        marginRight: 40,
        '&:hover': {
            cursor: 'pointer'
        }
    }
}));



const ExtendCatalog: React.FC = function ExtendCatalog( ) {
    const {
        container,
        singleProduct,
        listItem,
        list,
        icons,
        loadingContainer
    } = useStyles();

    type ExtendCatalogTypes = {
        categoryTitle?: string;
        childCategories?: CategoryTypes[];
        categoryCover?: string;
        products?: ProductTypes[];
        showPlusTenPage?: boolean
    }

    const history = useHistory();
    const location = useLocation();

    const [initialData, setInitialData] = useState<ExtendCatalogTypes | null>(null)
    const [updateContent, setUpdateContent] = useState<boolean>(false)
    const [hideProductsOnGoBack, setHideProductsOnGoBack] = useState<boolean>(false)
    const [showLoadingScreen, setShowLoadingScreen ] = useState<boolean>(true)

    useEffect(()=>{
        if(history && history.location && history.location.state) {
            const state: ExtendCatalogTypes = history.location.state
            setInitialData(state)
            setShowLoadingScreen(false)
            if(state && state.showPlusTenPage && state.showPlusTenPage){
                setUpdateContent(false)
                setHideProductsOnGoBack(true)
            } else {
                setHideProductsOnGoBack(false)
            }
        }
        //eslint-disable-next-line
    }, [history.location.state, location])


    const isAppleWebview = !!(window as any).webkit;
    const isAndroidWebview = !!(window as any).Android;
    const isDesktop = !isAppleWebview && !isAndroidWebview;

    return(

        <div
            className={container}
            data-testid={`extended-catalog`}
        >
            {
                showLoadingScreen && (
                    <div data-testid='loading-screen' className={loadingContainer}>
                        <CircularProgress
                            style={{ color: green[500] }}
                            thickness={4}
                            size={60}/>
                    </div>
                )
            }
            {
                initialData && !updateContent && initialData.showPlusTenPage && initialData.childCategories && initialData.childCategories.map((category) => {
                    return (category.enable && ((isAppleWebview && category.enable.ios) || (isAndroidWebview && category.enable.android) || (isDesktop && category.enable.desktop))) && (
                        <div className={singleProduct} key={category.id} data-testid={`category-${category.id}`}>
                            {
                                // category
                                category && (
                                    <List className={list}>
                                        <Paper elevation={1} className={listItem}
                                            onClick={(event) => {
                                                event.preventDefault();
                                                if(category.link) {
                                                    window.location.href = category.link;
                                                } else {
                                                    setUpdateContent(true)
                                                    history.push(`/extend-catalog/${category.title}`, {
                                                        categoryTitle: category.title,
                                                        categoryCover: category.cover,
                                                        products: initialData.products.filter(product => product.subcategory === category.id),
                                                        showPlusTenPage: false
                                                    });
                                                }
                                            }}
                                        >
                                            <ListItem className={listItem}>
                                                <ListItemAvatar style={{marginRight: 10}}>
                                                    <img src={category.icon} alt='' className={icons}/>
                                                </ListItemAvatar>
                                                <ListItemText primary={category.title.replace('_', ' ')} secondary={category.name} />
                                                <IconButton edge="end" aria-label="delete">
                                                    <ChevronRightIcon style={{color: '#878080'}}/>
                                                </IconButton>
                                            </ListItem>
                                        </Paper>
                                    </List>
                                )
                            }
                        </div>
                    )
                })
            }
            {
                initialData && !hideProductsOnGoBack && !initialData.showPlusTenPage && initialData.products && initialData.products.map((product) => {
                    return (
                        <div className={singleProduct} key={product.id} data-testid={`product-${product.id}`}>
                            {
                                // category
                                product && (
                                    <List className={list}>
                                        <Paper elevation={1} className={listItem}
                                               onClick={(event) => {
                                                   event.preventDefault();
                                                   history.push(`/product/${product.subcategory}`, {
                                                       categoryTitle: product.name,
                                                       categoryCover: product.design.background_image,
                                                       products: [product]
                                                   });
                                               }}
                                        >
                                            <ListItem className={listItem}>
                                                <ListItemAvatar>
                                                    <img src={product.design.background_image} alt='' className={icons} style={{marginRight: 10, background: `${product.design.background_color}`, borderRadius: '50%'}}/>
                                                </ListItemAvatar>
                                                <ListItemText primary={product.name.replace('_', ' ')} secondary={product.name} />
                                                <IconButton edge="end" aria-label="delete">
                                                    <ChevronRightIcon style={{color: '#878080'}}/>
                                                </IconButton>
                                            </ListItem>
                                        </Paper>
                                    </List>
                                )
                            }
                        </div>
                    )
                })
            }
        </div>
    );
};

export default ExtendCatalog;