import React, {useState} from 'react';
import LogoBlanco from '../../assets/logo-completo-blanco.png'
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import {useHistory} from 'react-router-dom'
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import TextField from '@material-ui/core/TextField';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import {InputAdornment} from "@material-ui/core";
import IconButton from '@material-ui/core/IconButton';

interface State {
    email: string;
    password: string;
    showPassword: boolean;
}

const Login: React.FC = function Login() {
    const history = useHistory()

    type TopupTextFieldErrorData = {
        fieldName: string;
        error: boolean;
        errorMessage: string;
    }
    const [ topupTextFieldErrorData, setTopupTextFieldErrorData ] = useState<TopupTextFieldErrorData[] | null>([]);
    const [ userEmail, setUserEmail ] = useState<string>('')
    const [ userPassword, setUserPassword ] = useState<string>('')
    const [ missMatchAuth, setMissMatchAuth ] = useState<boolean>(false)
    const [values, setValues] = React.useState<State>({
        email: '',
        password: '',
        showPassword: false,
    });
    setMissMatchAuth(false)
    const handleChange = (prop: keyof State) => (event: React.ChangeEvent<HTMLInputElement>) => {
        setValues({ ...values, [prop]: event.target.value });
    };

    const handleClickShowPassword = () => {
        setValues({ ...values, showPassword: !values.showPassword });
    };

    const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();
    };

    const handleLoginTextField: (setState: any) => (event: React.ChangeEvent<HTMLInputElement>) => void = function handleLoginTextField(setState) {
        return function handleOnChange(event: React.ChangeEvent<HTMLInputElement>) {
            event.preventDefault();
            const value = (event.target as HTMLInputElement).value;
            setState(value)
        }
    }

    const handleTopupButton: (state: any) => (event: React.MouseEvent<HTMLInputElement>) => void = function handleTopupButton(state) {
        return function handleOnChange(event: React.MouseEvent<HTMLInputElement>) {
            event.preventDefault();
            if(state === userEmail){
                const emailRegex: string = "^(([^<>()\\]\\\\.,;:\\s@']+(\\.[^<>()\\]\\\\.,;:\\s@']+)*)|('.+'))@((\\[[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}])|(([a-zA-Z\\-0-9]+\\.)+[a-zA-Z]{2,}))$"
                const regexpValidation: RegExp = new RegExp(emailRegex);
                const validation: boolean = regexpValidation.test(String(state).toLocaleLowerCase());
                if((validation && state.length > 1)) {
                    setTopupTextFieldErrorData(prevState => [
                        ...prevState.filter(errorData => errorData.fieldName !== 'email'),
                        {
                            fieldName: 'email',
                            error: false,
                            errorMessage: ''
                        }
                    ]);
                } else {
                    setTopupTextFieldErrorData(prevState => [
                        ...prevState.filter(errorData => errorData.fieldName !== 'email'),
                        {
                            fieldName: 'email',
                            error: true,
                            errorMessage: 'Debes ingresar un mail válido'
                        }
                    ]);
                }
            }


        }

    }

    const functionDisabled = () => {
        return false
    }

    return(
        <div style={{padding: 0, margin: 0}}>
            <div style={{
                maxHeight: '600px',
                height: '50vh',
                background: 'linear-gradient(180deg, rgba(109,212,0,1) 0%, rgba(40,195,57,1) 70%)'
            }}>
                <img
                    src={LogoBlanco}
                    alt='Logo Pago46'
                    style={{
                        display: 'block',
                        margin: 'auto',
                        position: 'relative',
                        top: '35%',
                        width: '50%',
                        maxWidth: 300,
                        verticalAlign: 'middle'
                    }}
                />

            </div>
            <div style={{
                marginTop: -50,
                background: 'white',
                borderRadius: '35px 35px 0px 0px',
            }}>
                <div style={{
                    padding: 40,
                    maxWidth: 600,
                    margin: 'auto'
                }}>
                    <Typography
                        data-testid={`login-card`}
                        style={{ fontSize: 20, margin: 'auto', width: 'fit-content'  }}
                        variant={'h2'}
                        color='textPrimary'>
                        Log-In
                    </Typography>

                    <FormControl fullWidth>
                        <TextField
                            error={topupTextFieldErrorData && topupTextFieldErrorData.length > 0 && topupTextFieldErrorData[0].error}
                            style={{
                                marginBottom: 18
                            }}
                            type='string'
                            required
                            value={userEmail}
                            fullWidth
                            inputProps={{
                                'data-testid': `debt-query-email-input`
                            }}
                            label='email'
                            onChange={handleLoginTextField(setUserEmail)}
                        />
                        <Typography variant='body2' style={{color: 'red'}}>
                            { topupTextFieldErrorData && topupTextFieldErrorData.length > 0 && topupTextFieldErrorData[0].errorMessage }
                        </Typography>
                    </FormControl>
                    <FormControl>
                        <InputLabel htmlFor="standard-adornment-password">Password</InputLabel>
                        <Input
                            id="standard-adornment-password"
                            type={values.showPassword ? 'text' : 'password'}
                            value={values.password}
                            onChange={handleChange('password')}
                            endAdornment={
                                <InputAdornment position="end">
                                    <IconButton
                                        aria-label="toggle password visibility"
                                        onClick={handleClickShowPassword}
                                        onMouseDown={handleMouseDownPassword}
                                    >
                                        {values.showPassword ? <Visibility /> : <VisibilityOff />}
                                    </IconButton>
                                </InputAdornment>
                            }
                        />
                        <TextField
                            style={{
                                marginBottom: 18
                            }}
                            type='string'
                            required
                            fullWidth
                            value={userPassword}
                            inputProps={{
                                'data-testid': `debt-query-password-input`
                            }}
                            label='password'
                            onChange={handleLoginTextField(setUserPassword)}
                        />
                    </FormControl>
                    {
                        missMatchAuth && (
                            <Typography variant='body2' style={{color: 'red'}}>
                                Email or Password are incorrect, please try again
                            </Typography>
                        )
                    }

                    <Typography
                        data-testid={`login-card`}
                        style={{ fontSize: 20, margin: 'auto', padding: 30, width: 'fit-content', textDecoration: 'underline' }}
                        variant={'body1'}
                        color='primary'
                        onClick={() => history.push('https://registration.pago46.com/')}
                    >
                        Trouble with login
                    </Typography>
                    <Button
                        style={{
                            marginTop: 8,
                            width: '100%',
                            borderRadius: 11,
                            color: 'white',
                            background: 'linear-gradient(262deg, rgba(109,212,0,1) 0%, rgba(40,195,57,1) 70%)'
                        }}
                        variant='contained'
                        size='large'
                        disabled={functionDisabled()}
                        data-testid='debt-query-button'
                        onClick={handleTopupButton(userEmail)}>
                        Login
                    </Button>

                    <div style={{
                        maxWidth: 600,
                        margin: 'auto',
                        paddingTop: 40
                    }}>
                        <Typography
                            data-testid={`login-card`}
                            style={{ fontSize: 13, margin: 'auto', paddingTop: 30, padding: 10, width: 'fit-content', display: 'inline', maxWidth: '50%'  }}
                            variant={'body1'}
                            color='textPrimary'
                        >
                            Don't have an account?
                        </Typography>
                        <Typography
                            data-testid={`login-card`}
                            style={{ fontSize: 13, margin: 'auto', paddingRight: 10, width: 'fit-content', textDecoration: 'underline', display: 'inline', maxWidth: '50%', float: 'right' }}
                            variant={'body1'}
                            color='primary'
                            onClick={() => history.push('https://registration.pago46.com/')}
                        >
                            Create a account
                        </Typography>
                    </div>
                </div>
            </div>
        </div>
    )

};

export default Login;