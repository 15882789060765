import { useHistory }       from 'react-router-dom';
import React                from 'react';
import { CarouselProps }    from './CarouselTypes';
import MoreIcon             from '../../assets/mas.png';

import {
    makeStyles,
    createStyles,
}                       from '@material-ui/core';
import Paper            from '@material-ui/core/Paper';
import ButtonBase       from '@material-ui/core/ButtonBase';
import GridListTile     from '@material-ui/core/GridListTile';

const useStyles = makeStyles(() => createStyles({
    gridListTileRoot: {
        height: 84,
        borderRadius: 6,
    },
    imgFullHeight: {
        maxWidth: '100%',
        height: 124,
        marginRight: 20
    },
    moreIconStyle: {
        borderRadius: '15px',
        height: '60px !important',
        width: '60px !important',
        marginTop: '13px !important'
    },
    liImageContainer: {
        height: '100% !important',
    },
    iconsFullWidth: {
        '& > div': {
            width: 'fit-content'
        }
    }
}));

const Carousel: React.FC<CarouselProps> = function Carousel({ childCategories, category, data }) {
    const {
        imgFullHeight,
        gridListTileRoot,
        moreIconStyle,
        liImageContainer,
        iconsFullWidth
    } = useStyles();

    const history = useHistory();

    return(
        <React.Fragment>
            {
                // subcategories
                childCategories && category && data && childCategories.map((childCategory, index, elements) => {
                    if ((index === 9 && elements.length > 10) || index < 9){
                        return childCategory.parent_category_id === category.id && (
                            <ButtonBase
                                style={{
                                    height: category.small_icons ? 92 : 'auto',
                                    width: category.small_icons ? 96 : 'auto',
                                    marginLeft: 'auto',
                                    marginRight: 'auto'
                                }}
                                key={childCategory.id}
                                data-testid={`carousel-${childCategory.id}-button`}
                                onClick={(event) => {
                                    event.preventDefault();
                                    if(childCategory.link) {
                                        window.location.href = childCategory.link;
                                    } else {
                                        if(index === 9 && elements.length >= 10){
                                            history.push(`/extend-catalog/${category.id}`, {
                                                categoryTitle: category.title,
                                                childCategories: childCategories.filter(product => category.id === product.parent_category_id),
                                                products: data.products,
                                                showPlusTenPage: true
                                            });
                                        } else if(data.products.filter(product => childCategory.id === product.subcategory).length > 1) {
                                            history.push(`/extend-catalog/${childCategory.id}`, {
                                                categoryTitle: childCategory.title,
                                                categoryCover: childCategory.cover,
                                                products: data.products.filter(product => childCategory.id === product.subcategory),
                                                showPlusTenPage: false
                                            });
                                        } else {
                                            history.push(`/product/${childCategory.id}`, {
                                                categoryTitle: childCategory.title,
                                                categoryCover: childCategory.cover,
                                                products: data.products.filter(product => childCategory.id === product.subcategory)
                                            });
                                        }
                                    }
                                }}>
                                {
                                    elements.length === 3 ? (
                                        <Paper
                                            style={{
                                                display: 'flex',
                                                margin: 2,
                                                width: '100%',
                                                height: '115px',
                                                color: 'white',
                                                background: index === 9 && elements.length >= 10 ? 'transparent' : childCategory.background_color,
                                                flexFlow: 'column nowrap',
                                                justifyContent: 'space-around',
                                                alignItems: 'center',
                                                alignContent: 'center',
                                                borderRadius: (category && category.small_icons) ? '100%' : 15,
                                                paddingTop: '-10px !important'
                                            }}
                                            elevation={0}
                                        >
                                            <GridListTile
                                                style={{
                                                    width: '100%',
                                                    height: '100% !important',
                                                    padding: '0 !important',
                                                    transition: 'all 0.3s cubic-bezier(.25,.8,.25,1)',
                                                    borderRadius: (category && category.small_icons) ? 100 : 15,
                                                    boxShadow: '2px 2px 2px 2px rgba(0,0,0,0.3)',
                                                }}
                                                className={liImageContainer}
                                                classes={{
                                                    root: gridListTileRoot,
                                                    imgFullHeight: imgFullHeight
                                                }}>

                                                <img
                                                    style={{
                                                        borderRadius: (category && category.small_icons) ? 100 : 15,
                                                        width: '100%',
                                                        height: '100%',
                                                    }}
                                                    className={(index === 9 && elements.length >= 10) ? moreIconStyle : ''}
                                                    src={
                                                        index === 9 && elements.length >= 10
                                                            //Last button to see more
                                                            ? MoreIcon
                                                            : (index === 9 && elements.length < 10) || index < 9
                                                            ?   childCategory && childCategory.icon
                                                                ? childCategory.icon
                                                                : 'https://via.placeholder.com/128x128?text=cover'
                                                            : null
                                                    }
                                                    alt=' '
                                                />
                                            </GridListTile>
                                        </Paper>
                                    ) : (
                                        <Paper
                                            style={{
                                                display: 'flex',
                                                margin: 5,
                                                width: (category && category.items_per_slide && category.items_per_slide === 1) ? 'auto' : category.small_icons ? 82 : 'auto',
                                                height: category.small_icons ? 84 : 'auto',
                                                maxHeight: !category.small_icons && 124,
                                                color: 'white',
                                                background: index === 9 && elements.length >= 10 ? 'transparent' : childCategory.background_color,
                                                flexFlow: 'column nowrap',
                                                justifyContent: 'space-around',
                                                alignItems: 'center',
                                                alignContent: 'center',
                                                borderRadius: (category && category.small_icons) ? '100%' : 15,
                                                paddingTop: '-10px !important'
                                            }}
                                            elevation={0}
                                            data-testid={`searchHere1`}
                                        >
                                            <GridListTile
                                                style={{
                                                    height: category.small_icons ? 84 : 'auto',
                                                    maxHeight: !category.small_icons && 124,
                                                    padding: '0 !important',
                                                    transition: 'all 0.3s cubic-bezier(.25,.8,.25,1)',
                                                    borderRadius: (category && category.small_icons) ? 100 : 15,
                                                    width: (category && category.items_per_slide && category.items_per_slide === 1) ? 'auto' : category.small_icons ? 82 : 'auto',
                                                    boxShadow: '2px 2px 2px 2px rgba(0,0,0,0.3)',
                                                }}
                                                data-testid={`searchHere2`}
                                                classes={{
                                                    root: gridListTileRoot,
                                                    imgFullHeight: imgFullHeight
                                                }}
                                                className={iconsFullWidth}
                                            >

                                                <img
                                                    style={{
                                                        marginTop: category.small_icons ? 0 : 'auto',
                                                        borderRadius: (category && category.small_icons) ? 100 : 15,
                                                        height: category.small_icons ? 84 : '',
                                                        maxWidth: (category && !category.small_icons && category.items_per_slide === 1 && elements.length === 1) ? '100%' : 'auto',
                                                        width: (category && category.items_per_slide && category.items_per_slide === 1 && !category.small_icons) ? 'auto' : category.small_icons ? 84 : 'auto',
                                                    }}
                                                    data-testid={`searchHere3`}
                                                    className={(index === 9 && elements.length >= 10) ? moreIconStyle : ''}
                                                    src={
                                                        index === 9 && elements.length >= 10
                                                            //Last button to see more
                                                            ? MoreIcon
                                                            : (index === 9 && elements.length < 10) || index < 9
                                                            ?   childCategory && childCategory.icon
                                                                ? childCategory.icon
                                                                : 'https://via.placeholder.com/128x128?text=cover'
                                                            : null
                                                    }
                                                    alt=' '
                                                />
                                            </GridListTile>
                                        </Paper>
                                    )
                                }
                            </ButtonBase>
                        );
                    }
                    else {
                        return null
                    }
                })
            }
        </React.Fragment>
    );
};

export default Carousel;
