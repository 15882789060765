import React from 'react'
import Paper from "@material-ui/core/Paper";
import ErrorIcon from '../../assets/error-de-localizacion.png'
import ErrorBackground from '../../assets/error-de-localizacion-background.jpg'
import Typography from "@material-ui/core/Typography";
import makeStyles from "@material-ui/core/styles/makeStyles";
import {createStyles} from "@material-ui/core";
import Button from "@material-ui/core/Button";

const useStyles = makeStyles(theme => createStyles({
    responseContainer: {
        backgroundColor: 'transparent',
        padding: 32,
        display: 'flex',
        marginLeft: 'auto',
        marginRight: 'auto',
        flexFlow: 'column nowrap',
        alignItems: 'center',
        width: 320,
        maxWidth: '600px',
        zIndex: 9,
    },
    iconFailure: {
        color: 'red',
        fontSize: 120,
        width: 'auto',
        height: '30vh',
        maxHeight: 100,
        zIndex: 9,
    },
    titleTheme: {
        color: 'black',
        fontWeight: 'normal',
        width: '80%',
        maxWidth: 200,
        marginTop: 20,
        textAlign: 'center',
        zIndex: 9,
    },
    text: {
        color: 'dimgrey',
        fontSize: 14,
        textAlign: 'center',
        width: '70%',
        maxWidth: 200,
        marginTop: 14,
        zIndex: 9,
    },
    backgroundFailure: {
        position: 'absolute',
        [theme.breakpoints.down(480)]: {
            right: 0
        },
        top: 0,
        zIndex: 1,
        height: '100vh'
    },
    button: {
        color: "white",
        backgroundColor: "#28C339",
        borderColor: "#28C339",
        marginTop: 10,
        zIndex: 9,
        width: '100%',
        "&:hover":{
            backgroundColor: "white",
            color: '#28C339',
            borderColor: "#28C339",
        }
    },
}));

const NoCountryAvailable: React.FC = function NoCountryAvailable() {
    const {
        responseContainer,
        iconFailure,
        titleTheme,
        text,
        backgroundFailure,
        button
    } = useStyles();

    const urlHome = 'https://bit.ly/3eqTdex'

    return(
        <div data-testid='no-country-available'>
            <Paper data-testid='error-screen' elevation={0} className={responseContainer} style={{ width: '80%' }}>
                <img alt="" src={ErrorBackground} className={backgroundFailure}/>
                <img alt="" src={ErrorIcon} className={iconFailure}/>
                <Typography variant={'h6'} className={titleTheme}>Parece que aún no estamos presentes en el país donde te encuentras!</Typography>
                <a href={urlHome}>
                    <Button
                        color="primary"
                        className={button}
                        variant="outlined"
                    > Visita nuestro sitio web
                    </Button>
                </a>
                <Typography variant={'body2'} className={text}>Para enterarte de todas las novedades</Typography>
            </Paper>
        </div>
    )
}

export default NoCountryAvailable