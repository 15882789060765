import React   from 'react';

import Paper from "@material-ui/core/Paper";
import pagaqui from '../../assets/pagaqui.png'
import {createStyles, makeStyles} from "@material-ui/core";
import SearchHeader from "../Search/SearchHeader";
import {CatalogProps} from "./CatalogTypes";

const useStyles = makeStyles((theme) => createStyles({
    container: {
        backgroundColor: '#F9F9F9',
        width: '100vw',
        paddingTop: '10px',
        [theme.breakpoints.down(611)]: {
        },
        [theme.breakpoints.up(612)]: {
            paddingLeft: '25vw',
            paddingRight: '25vw',
            backgroundColor: 'white',
            width: '50vw',
        },
        [theme.breakpoints.up(1000)]: {
            paddingLeft: '35vw',
            paddingRight: '35vw',
            backgroundColor: 'white',
            width: '400px',
        },
    }
}));

const FullProductList: React.FC<CatalogProps> = function FullProductList({data}) {
    const { container } = useStyles();

    return(
        <div
            className={container}
            data-testid='one-list-products-screen'
        >
            <section style={{
                width: '90%',
                display:'flex',
                flexFlow: 'column nowrap',
                alignItems: 'center',
                margin: '0 auto',
                padding: '0, 16'
            }}>
                <Paper
                    style={{
                        display: 'flex',
                        width: '100%',
                        color: 'transparent',
                        flexFlow: 'column nowrap',
                        justifyContent: 'space-evenly',
                        alignItems: 'center',
                        alignContent: 'center',
                        paddingBottom: 16
                    }}
                    elevation={4}
                >
                    <div style={{ marginBottom: 12, width: '100%',  background: '#ff0000' }}>
                        <picture>
                            <img
                                style={{
                                    maxWidth: '100%',
                                    display: 'block',
                                    margin: '0 auto'
                                }}
                                data-testid='product-image'
                                src={pagaqui}
                                alt='product'
                            />
                        </picture>
                    </div>
                    <SearchHeader data={data}/>
                </Paper>
            </section>
        </div>
    )
};

export default FullProductList;