import Carousel                         from "../Carousel/Carousel";
import { CatalogProps }                 from './CatalogTypes';
import {Category as CategoryTypes} from '../../CyclopsTypes';

import {
    makeStyles,
    createStyles }                      from '@material-ui/core';
import React, {useEffect, useState}     from 'react';

import Typography       from '@material-ui/core/Typography';
import GridList         from '@material-ui/core/GridList';
import Paper from "@material-ui/core/Paper";
import pagaqui from "../../assets/pagaqui.png";
import { useHistory } from 'react-router-dom'
const useStyles = makeStyles((theme) => createStyles({
    container: {
        backgroundColor: '#F9F9F9',
        width: '100vw',
        paddingTop: 1,
        [theme.breakpoints.up(612)]: {
            paddingLeft: '25vw',
            paddingRight: '25vw',
            backgroundColor: 'white',
            width: '50vw',
        },
        [theme.breakpoints.up(1000)]: {
            paddingLeft: '35vw',
            paddingRight: '35vw',
            backgroundColor: 'white',
            width: '400px',
        },
    },
    aLotMerchantRow: {
        marginTop: 6,
        backgroundColor: 'white',
        clear: 'both'
    },
    fewMerchantRow: {
        marginTop: 6,
        backgroundColor: '#F9F9F9',
        clear: 'both',
        [theme.breakpoints.up(600)]: {
            backgroundColor: 'white',
        },
    },
    highLightContainer: {
        padding: 6,
        clear: 'both',
        backgroundColor: '#F9F9F9',
        borderRadius: 10,
    }
}));

const Catalog: React.FC<CatalogProps> = function Catalog({ data }) {
    const {
        container,
        aLotMerchantRow,
        fewMerchantRow,
        highLightContainer
    } = useStyles();

    const history = useHistory()

    const [childCategories, setChildCategories] = useState<CategoryTypes[] | null>(null)
    const [categories, setCategories] = useState<CategoryTypes[] | null>(null)
    const [highlightCategory, setHighlightCategory] = useState<CategoryTypes | null>(null)
    const [imgMexicoClick, setImgMexicoClick] = useState<any>(null)

    const highlightId: number = 8994

    useEffect(()=>{
        const childCategoriesArr: CategoryTypes[] = data && data.categories && data.categories.filter(function categoriesFilter(category) {
            return category.icon && category
        });
        setChildCategories(childCategoriesArr)

        const categoriesArr: CategoryTypes[] = data && data.categories && data.categories.filter(row => {
            if(row.id === highlightId) return null
            if(row.parent_category_id === null || !("parent_category_id" in row)) return row

            return null
        })
        setCategories(categoriesArr)

        const highlightCategoryProvisional: CategoryTypes = data && data.categories && data.categories.filter(row => row.name === "Highlights")[0]
        setHighlightCategory(highlightCategoryProvisional)
    }, [data])

    useEffect(()=>{
        if(data && history.location.hash === '#currency=MXN' && imgMexicoClick){
            imgMexicoClick.click()
        }
        //eslint-disable-next-line
    }, [imgMexicoClick])

    const itHaveXMultiChildCategories: (category: CategoryTypes, length: number) => boolean = function itHaveMultiChildCategories(category, length) {
        const response = childCategories.filter(row => row.parent_category_id === category.id)
        return response.length === length
    }

    const isAppleWebview = !!(window as any).webkit;
    const isAndroidWebview = !!(window as any).Android;
    const isDesktop = !isAppleWebview && !isAndroidWebview;

    return(
        <div className={container} data-testid={`catalog-screen`}>
            {
                //only highlights carousel
                highlightCategory && (highlightCategory.enable && ((isAppleWebview && highlightCategory.enable.ios) || (isAndroidWebview && highlightCategory.enable.android) || (isDesktop && highlightCategory.enable.desktop))) && (
                    <div
                        className={highLightContainer}
                        key={highlightCategory.id}
                        data-testid={`category-${highlightCategory.id}`}
                    >
                        <Typography
                            style={{
                                margin: '0 0 10px 0',
                                fontSize: 14,
                                color: 'slategray',
                            }}
                            variant='h6'
                            gutterBottom
                            data-testid={`category-${highlightCategory.id}-title`}>
                            Highlights
                        </Typography>
                        <GridList
                            style={{
                                borderRadius: 6,
                                width: '100%',
                                display: 'flex',
                                flexWrap: 'nowrap',
                                transform: 'translateZ(0)',
                                background: 'none',
                                overflowY: 'hidden',
                                margin: 0,
                            }}
                            cols={(highlightCategory && highlightCategory.items_per_slide && highlightCategory.items_per_slide === 1) ? 1 : 3}
                            spacing={6}
                            cellHeight={130}>
                                    <Carousel
                                        childCategories={childCategories.filter(row => row.parent_category_id === highlightCategory.id)}
                                        category={highlightCategory}
                                        data={data}
                                    />
                        </GridList>
                    </div>
                )
            }
            {
                //other carouselles
                categories && categories.map((category) => {
                    return (category.enable && ((isAppleWebview && category.enable.ios) || (isAndroidWebview && category.enable.android) || (isDesktop && category.enable.desktop))) && (
                        <div
                            className={category.items_per_slide >= 3 ? aLotMerchantRow : fewMerchantRow}
                            key={category.id}
                            data-testid={`category-${category.id}`}
                            style={{
                                padding: itHaveXMultiChildCategories(category, 3) ? '0px 0px 0px 15px' : 5,
                            }}
                        >
                            {
                                ( !itHaveXMultiChildCategories(category, 1) ) && (
                                    <Typography
                                        style={{
                                            paddingLeft: itHaveXMultiChildCategories(category, 3) ? 5 : 15,
                                            fontSize: 14,
                                            color: 'slategray',
                                        }}
                                        variant='h6'
                                        data-testid={`category-${category.id}-title`}>
                                        {category.title}
                                    </Typography>

                                )
                            }
                            {
                                // category
                                category && category.countries[0] !== "MX" &&(
                                    <GridList
                                        style={{
                                            width: '95%',
                                            display: 'flex',
                                            flexWrap: 'nowrap',
                                            transform: 'translateZ(0)',
                                            background: 'none',
                                            overflowY: 'hidden',
                                            margin: itHaveXMultiChildCategories(category, 3) ? 0 : 5,
                                            height: 'auto'
                                        }}
                                        data-testid={`carousel-${category.id}`}
                                        cols={(category && category.items_per_slide && category.items_per_slide === 1) ? 1 : 3}
                                        spacing={6}
                                        cellHeight={130}>
                                            <Carousel
                                                childCategories={childCategories.filter(row => row.parent_category_id === category.id)}
                                                category={categories.filter(row => row.id === category.id)[0]}
                                                data={data}
                                            />
                                    </GridList>
                                )
                            }

                            {
                                //Mexico single button
                                itHaveXMultiChildCategories(category, 1) && category.countries[0] === "MX" && (
                                    <div style={{ width: '100%', maxWidth: 480, marginLeft: 'auto', marginRight: 'auto' }} data-testid='one-list-products-screen'>
                                        <section style={{
                                            width: '90%',
                                            display:'flex',
                                            flexFlow: 'column nowrap',
                                            alignItems: 'center',
                                            margin: '0 auto',
                                            marginTop: 40,
                                            padding: 16
                                        }}>
                                            <Paper
                                                style={{
                                                    display: 'flex',
                                                    width: '100%',
                                                    color: 'transparent',
                                                    flexFlow: 'column nowrap',
                                                    justifyContent: 'space-evenly',
                                                    alignItems: 'center',
                                                    alignContent: 'center',
                                                }}
                                                elevation={4}
                                            >
                                                <div style={{ width: '100%',  background: '#ff0000' }}>
                                                    <picture>
                                                        <img
                                                            style={{
                                                                maxWidth: '100%',
                                                                display: 'block',
                                                                margin: '0 auto'
                                                            }}
                                                            data-testid='product-image'
                                                            src={pagaqui}
                                                            ref={reference => setImgMexicoClick(reference)}
                                                            alt='product'
                                                            onClick={(event) => {
                                                                event.preventDefault();
                                                                history.push(`/extend-catalog/${category.id}`, {
                                                                    categoryTitle: category.title,
                                                                    childCategories: childCategories.filter(product => category.id === product.parent_category_id),
                                                                    products: data.products,
                                                                });
                                                            }}
                                                        />
                                                    </picture>
                                                </div>
                                            </Paper>
                                        </section>
                                    </div>
                                )
                            }
                        </div>
                    )
                })
            }
        </div>
    );
};

export default Catalog;