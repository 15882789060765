import {
    makeStyles,
    createStyles }                      from '@material-ui/core';
import { useHistory }      from 'react-router-dom';
import React, {useEffect, useState}     from 'react';
import { Category as CategoryTypes }    from '../../CyclopsTypes';

import TextField        from '@material-ui/core/TextField';
import Autocomplete     from '@material-ui/lab/Autocomplete';
import {CatalogProps}   from "../Catalog/CatalogTypes";
import SearchIcon       from '@material-ui/icons/Search';
import Grid             from '@material-ui/core/Grid';


const useStyles = makeStyles(() => createStyles({
    searchBox: {
        width: '90%',
        margin: '16px auto',
        float: 'right',
    },
    searchIcon: {
        marginLeft: 15,
        margin: 5,
        color: '#ABABAB'
    },
    searchInput: {
        width: '100%',
        border: 'none',
        color: '#AFAFAF',
        marginBottom: 5,
        '& > div::before': {
            border: 'none'
        }
    },
    searchInputBox:{
        width: '80%',
    }
}));

const SearchHeader: React.FC<CatalogProps> = function Search({data}) {
    const {
        searchBox,
        searchIcon,
        searchInput,
        searchInputBox
    } = useStyles();

    const [ childCategories, setChildCategories ] = useState<CategoryTypes[] | null>(null)

    const history = useHistory();

    useEffect(()=>{
        const iconsProd: CategoryTypes[] = data && data.categories && data.categories.filter(function categoriesFilter(category) {
            return category.icon && category
        });
        setChildCategories(iconsProd)
    }, [data])


    return(
        <Autocomplete
            className={searchBox}
            options={data && data.products}

            autoHighlight
            getOptionLabel={option => option.name}
            onChange={(event: any, value: any) => {
                history.push(`/product/${value.id}`, {
                    categoryTitle: childCategories && childCategories.filter(category => category.id === value.subcategory && category.title).length > 0 ? childCategories.filter(category => category.id === value.subcategory && category.title)[0].title : '',
                    categoryCover: childCategories && childCategories.filter(category => category.id === value.subcategory && category.cover).length > 0 ? childCategories.filter(category => category.id === value.subcategory && category.cover)[0].cover : '',
                    products: [ value ]
                });
            }}
            renderInput={params => {
                return(
                    <div
                        style={{
                            border: '1px #E2E2E2 solid',
                            borderRadius: '10px'
                        }}
                    >
                        <Grid container alignItems="flex-end">
                            <Grid
                                item
                                className={searchIcon}
                            >
                                <SearchIcon />
                            </Grid>
                            <Grid item className={searchInputBox}>
                                <TextField
                                    className={searchInput}
                                    {...params}
                                    label={'Buscar producto: '}
                                    inputProps={{
                                        ...params.inputProps,
                                        autoComplete: 'new-password', // disable autocomplete and autofill
                                        'data-testid': 'country.userSignup.inputLabels.nationality'
                                    }}
                                />
                            </Grid>
                        </Grid>
                    </div>
                );
            }}
        />
    );
};

export default SearchHeader;