import {
    ProductAppBarProps
} from './ProductTypes';
import {
    makeStyles,
    createStyles,
}                       from '@material-ui/core';
import {
    useHistory,
}                       from 'react-router-dom';
import React            from 'react';
import AppBar           from '@material-ui/core/AppBar';
import Toolbar          from '@material-ui/core/Toolbar';
import Typography       from '@material-ui/core/Typography';
import IconButton       from '@material-ui/core/IconButton';
import ArrowBackIcon    from '@material-ui/icons/ArrowBack';


const useStyles = makeStyles(() => createStyles({
    toolbar: {
        padding: 0,
        minWidth: 320,
        width: '100%'
    },
    title: {
        flexGrow: 1,
        fontSize: 14
    },
}));


const ProductAppBar: React.FC<ProductAppBarProps> = function ProductAppBar({userSelectedProduct, autoSelectedProduct, initialData}) {
    const {
        toolbar,
        title,
    } = useStyles();

    const history = useHistory();

    return(
        <AppBar
            style={{
                flexGrow: 1,
            }}
            position='sticky'>
            <Toolbar
                className={toolbar}>
                <IconButton
                    style={{ color: `${(userSelectedProduct && userSelectedProduct.design.font_color) || (autoSelectedProduct && autoSelectedProduct.design.font_color) || '#28c339'}`, }}
                    aria-label='volver'
                    onClick={event => {
                        event.preventDefault();
                        history.go(-1);
                    }}>
                    <ArrowBackIcon
                        style={{
                            color: initialData && initialData.products && initialData.products.length !== 0 && (initialData.products[0].design.background_color === '#F0FFFF' || initialData.products[0].design.background_color === '#FFFFFF') ? '#000000' : '#FFFFFF'
                        }}/>
                </IconButton>
                <Typography
                    variant='h6'
                    className={title}
                    style={{
                        color: initialData && initialData.products && initialData.products.length !== 0 && (initialData.products[0].design.background_color === '#F0FFFF' || initialData.products[0].design.background_color === '#FFFFFF') ? '#000000' : '#FFFFFF'
                    }}>
                    { initialData && initialData.categoryTitle }
                </Typography>
            </Toolbar>
        </AppBar>
    );
};

export default ProductAppBar;
