import React, {useState}               from 'react';

import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import IconButton from "@material-ui/core/IconButton";
import Modal from '@material-ui/core/Modal';

function rand() {
    return Math.round(Math.random() * 20) - 10;
}

function getModalStyle() {
    const top = 50 + rand();
    const left = 50 + rand();

    return {
        top: `${top}%`,
        left: `${left}%`,
        transform: `translate(-${top}%, -${left}%)`,
    };
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        paper: {
            position: 'absolute',
            width: 400,
            backgroundColor: theme.palette.background.paper,
            border: '2px solid #000',
            boxShadow: theme.shadows[5],
            padding: theme.spacing(2, 4, 3),
        },
    }),
);

type ModalUrl = {
    modalUrl: string
}
const InfoModal: React.FC<ModalUrl> = function InfoModal({modalUrl}) {
    const classes = useStyles();

    const [modalStyle] = React.useState(getModalStyle);
    const [open, setOpen] = useState<boolean>(false);

    const handleOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const modalBody = (
        <div style={modalStyle} className={classes.paper}>
            <img src={modalUrl} alt='' style={{width: 'fit-content'}}/>
        </div>
    );

    return(
        <React.Fragment>
            <IconButton
                style={{width: 'fit-content'}}
            >
                <HelpOutlineIcon
                    style={{ color: '#28C339' }}
                    onMouseEnter={handleOpen}
                    onClick={handleOpen}
                />
            </IconButton>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="Aqui debes buscar el código solicitado"
            >
                {modalBody}
            </Modal>
        </React.Fragment>
    );
};

export default InfoModal;
