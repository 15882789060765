import {
    makeStyles,
    createStyles }          from '@material-ui/core';
import {useHistory, useLocation} from 'react-router-dom';
import React, {useEffect, useState} from 'react';
import {CatalogProps}       from "../Catalog/CatalogTypes";
import NavigationHeader        from "./NavigationHeader";
import SearchHeader from "./SearchHeader";
import {Product as ProductTypes} from "../../CyclopsTypes";


const useStyles = makeStyles((theme) => createStyles({
    container: {
        height: '14vh',
    },
    containerFixed: {
        display: 'flex',
        position: 'fixed',
        top: 0,
        left: 0,
        backgroundColor: 'white',
        zIndex: 10,
        [theme.breakpoints.down(611)]: {
            width: '-webkit-fill-available',
        },
        [theme.breakpoints.up(612)]: {
            paddingLeft: '25vw',
            paddingRight: '27vw',
            width: '48vw',
        },
        [theme.breakpoints.up(1000)]: {
            paddingLeft: '35vw',
            paddingRight: '35vw',
            width: '400px',
        },
    },
}));


const ContainerHeader: React.FC<CatalogProps> = function HeaderBar({data}) {
    const {
        container,
        containerFixed
    } = useStyles();

    const location = useLocation();
    const history = useHistory();

    type ExtendCatalogTypes = {
        categoryTitle?: string;
        categoryCover?: string;
        products?: ProductTypes[];
    }

    const hideHeaderBar :boolean                    = location.pathname.includes('product') || location.pathname.includes('login')
    const showCatalogAppBar :boolean                = location.pathname.includes('extend-catalog')
    const [ initialData, setInitialData ]           = useState<ExtendCatalogTypes | null>(null)
    const [ isMexico, setIsMexico ]                 = useState<boolean>(false)
    const [ hideSearch, setHideSearch ]             = useState<boolean>(false)

    useEffect(()=>{
        const currency: string = window && window.location && window.location.hash && window.location.hash.replace('#currency=', '')
        currency === 'MXN' && setIsMexico(true)

        if(history && history.location && history.location.state) {
            const state: ExtendCatalogTypes = history.location.state
            setInitialData(state)
        }
        //eslint-disable-next-line
    }, [history.location.state])

    useEffect(()=>{
        isMexico && window && window.location && window.location.hash === '' && setHideSearch(true)
        isMexico && window && window.location && window.location.hash === '#currency=MXN' && setHideSearch(false)
        //eslint-disable-next-line
    }, [isMexico, window.location.hash])

    return(
        <React.Fragment>
            {
                !hideHeaderBar && !hideSearch && (
                    <div className={container}>
                        <div className={containerFixed}>
                            {
                                !hideSearch && (
                                    <SearchHeader data={data}/>
                                )
                            }
                            {
                                showCatalogAppBar && initialData && initialData.categoryTitle && (
                                    <NavigationHeader hideSearch={hideSearch}/>
                                )
                            }
                        </div>
                    </div>
                )
            }
        </React.Fragment>
    );
};

export default ContainerHeader;